import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";

function CategoriesList({ dataObj }) {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [categoryData, setCategoryData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                data: dataObj,
            };
            ApiService.postData("categoryByIds", dataString).then((res) => {
                if (res.status == "success") {
                    setDefaultImage(res.default_image_path)
                    setCategoryImagePath(res.categoryImageUrl)
                    setCategoryData(res.resCategoryData);
                    setSpinnerLoading(false);
                } else {
                    setSpinnerLoading(false);
                }
            }).catch(()=>{
                setSpinnerLoading(false)
            });
        }
        didMountRef.current = false;
    }, []);
    return (
        <>
            {spinnerLoading ? (
                <div className="row">
                    {[...Array(3)].map((_, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="texmbox">
                                <div className="texmboxMedia">
                                    <Skeleton variant="text" width={300} height={250} />
                                </div>
                                <div className="texmboxContent">
                                    <h4>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </h4>
                                    <p>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                categoryData && categoryData.length > 0 ? (
                    Number(dataObj.home_display_type) === 2 ? (
                        <div className="featuredcatSlidersec">
                            <div className="row">
                                {categoryData.map((value, index) => (
                                    <div className={`col-lg-${dataObj.home_column}`} key={index}>
                                        <div className="featuredcatbox overlay-zoom">
                                            <a href={"/category/" + value.cat_slug}>
                                                {value.cat_video ?
                                                    <video src={`${categoryImagePath}${value.cat_video}`} muted autoPlay loop /> :
                                                    <img src={value.cat_image !== null && value.cat_image !== "" ? categoryImagePath + value.cat_image : defaultImage} alt={value.cat_name}/>}
                                            </a>
                                            {dataObj.home_cat_display_title == 1 && (<div className="featuredcatboxtitle"><a href={"/category/" + value.cat_slug}>{value.cat_name}</a></div>)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="featuredcatSlidersec">
                            <Swiper
                                loop={true}
                                spaceBetween={15}
                                className="featuredcatSlider"
                                navigation={{
                                    nextEl: ".swiper-button-next",
                                    prevEl: ".swiper-button-prev",
                                }}
                                slidesPerView={dataObj.home_column}
                            >
                                {categoryData.map((value, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="featuredcatbox overlay-zoom">
                                            <a href={"/category/" + value.cat_slug}>
                                                {value.cat_video ?
                                                    <video src={`${categoryImagePath}${value.cat_video}`} muted autoPlay loop /> :
                                                    <img src={value.cat_image !== null && value.cat_image !== "" ? categoryImagePath + value.cat_image : defaultImage} alt={value.cat_name}/>}
                                            </a>
                                            {dataObj.home_cat_display_title == 1 && (<div className="featuredcatboxtitle"><a href={"/category/" + value.cat_slug}>{value.cat_name}</a></div>)}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )
                ) : null
            )}
        </>
    );
}

export default CategoriesList;
