import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Component/Service/apiservices";
import { useContext, useState } from "react";
import DataContext from "../../Component/Element/context";
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

const MyProfile = () => {
    const contextValues = useContext(DataContext)
    const [buttonLoader, setButtonLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [resetPassword, setresetPassword] = useState({
        new_password: "",
        confirm_password: ""
    })
    const [step, setStep] = useState(1)
    const navigate = useNavigate()
    const resetPasswordProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("passwordRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            if (resetPassword.confirm_password.trim() !== resetPassword.new_password.trim()) {
                setErrorMessage('Password and confirm password should be same');
                return false;
            }
            const dataString = {
                user_password: resetPassword.new_password,
                user_confirm_password: resetPassword.confirm_password,

            }
            setButtonLoader(true)
            ApiService.postData("userchangepassword", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setStep(3)
                    setButtonLoader(false)
                    setTimeout(() => {
                        setStep(1)
                        setErrorMessage('')
                    }, 2500)
                } else if(res.status=='error' && res.message=='Session expired') {
                   localStorage.removeItem('USER_TOKEN')
                   navigate('/')
                }else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            })
        }
    }
    const handleForgetPasswordChange = (e) => {
        const { name, value } = e.target;
        setresetPassword(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

  
    const navigateback = (e) => {
        e.preventDefault()
       
        if (window.history.length > 2) { 
          navigate(-1);
        } else {
          navigate('/');
        }
      };
   

    return (<>
       
        <BrowserView>
        <Header innerHeader={'innerHeader'}></Header>
            <div className="subheader ">
                <Container>
                    <Row>
                        <Col lg={12}>

                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/my-account">Overview</Breadcrumb.Item>
                                <Breadcrumb.Item active>Account Detail</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>

            </div>

            <section className="section-gap-medium">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <AccountSidebar></AccountSidebar>
                        </div>
                        <div className="col-lg-9">
                            <div className="section-title">
                                <h2>Account Detail</h2>
                            </div>
                            <div>
                                <p>{contextValues?.userData?.user_fname ? contextValues?.userData?.user_fname : ''}{' '}{contextValues?.userData?.user_lname ? contextValues?.userData?.user_lname : ''}</p>
                                <p>{contextValues?.userData?.user_email}</p>
                                {step == 1 && <p><a className="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={() => { setStep(2); setErrorMessage('') }}>Reset Password</a></p>}

                            </div>
                            {step == 2 && (
                                <div className="loginrigisterbox">
                                    <div className="tx-center mb-20">
                                        <h4>Reset password</h4>

                                    </div>
                                    {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}

                                    <div className="form-group mb-15">
                                        <input type="password" placeholder="Password" className='passwordRequired' name='new_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                                    </div>
                                    <div className="form-group mb-15">
                                        <input type="password" placeholder="Confirm Password" className='passwordRequired' name='confirm_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                                    </div>

                                    <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={(e) => { resetPasswordProcess() }} disabled={buttonLoader}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("CHANGE")}</button>
                                </div>


                            )}
                            {step == 3 && (
                                <div className="loginrigisterbox">
                                    <div className="tx-center mb-20">
                                        <img src='/img/verified.gif' className="wd-200 mb-20" />
                                        <h2 style={{ textAlign: 'center' }}>Thank you for submitting </h2>
                                        <p style={{ textAlign: 'center' }}>Your Password has been Changed</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <Footer></Footer>
        </BrowserView>
        <MobileView>
   
            <header className="header mheader">
                <div className="header-left">
                    <div className="header-title">
                        <a href="javasccript:void(0)" onClick={(e) => { navigateback(e) }}><i className="ri-arrow-left-line ri-xl mr-10"></i></a>
                        Account Detail
                    </div>
                </div>
            </header> 
            
            <section className="section-gap-medium">
            <div className="container">
                <div className="row g-3">
                 
                    <div>
                        <p>{contextValues?.userData?.user_fname ? contextValues?.userData?.user_fname : ''}{' '}{contextValues?.userData?.user_lname ? contextValues?.userData?.user_lname : ''}</p>
                        <p>{contextValues?.userData?.user_email}</p>
                        {step == 1 && <p><a className="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={() => { setStep(2); setErrorMessage('') }}>Reset Password</a></p>}
                    </div>
                    {step == 2 && (
                        <div className="loginrigisterbox">
                            <div className="tx-center mb-20">
                                <h4>Reset password</h4>
                            </div>
                            {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                            <div className="form-group mb-15">
                                <input type="password" placeholder="Password" className='passwordRequired' name='new_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                            </div>
                            <div className="form-group mb-15">
                                <input type="password" placeholder="Confirm Password" className='passwordRequired' name='confirm_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                            </div>

                            <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={(e) => { resetPasswordProcess() }} disabled={buttonLoader}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("CHANGE")}</button>
                        </div>
                    )}
                    {step == 3 && (
                        <div className="loginrigisterbox">
                            <div className="tx-center mb-20">
                                <img src='/img/verified.gif' className="wd-200 mb-20" />
                                <h2 style={{ textAlign: 'center' }}>Thank you for submitting </h2>
                                <p style={{ textAlign: 'center' }}>Your Password has been Changed</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section></MobileView>

        

    </>)
}

export default MyProfile