import Footer from "../../../Component/Footer"
import Header from "../../../Component/Header"

const OrderStatus=()=>{
    return(<>
    
    <Header innerHeader={'innerHeader'}></Header>
    <section className="section-gap-medium tx-center ordersection" style={{background:'url(https://padmakshyajaipur.com/csadmin/public/img/uploads/appearance/1722344231.jpg)'}}>
            <div className="section-title tx-center mb-40">
                <h2>Order Tracking</h2>
                <p>To track your order please enter your Order ID in the box below and press the "Track" button. This was given to you on<br></br>
                your receipt and in the confirmation email you have recieved
                 </p>
            </div>
            <h3 className="tx-center">Order Id</h3>
            <div className="trakorderbox">
               
                <input type="text"></input>
                <button className="btn btn-primary btn-lg" type="button">Track</button>
            </div>
        </section>
        
    <Footer></Footer>
    
    </>)
}


export default OrderStatus