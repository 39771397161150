import Footer from "../../../Component/Footer"
import Header from "../../../Component/Header"

const ReturnExchnage=()=>{
    return(<>
    <Header innerHeader={'innerHeader'}></Header>
    <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <h2>Return Exchange</h2>
            </div>
        </section>
       
    <Footer></Footer>
    </>)
}

export default ReturnExchnage