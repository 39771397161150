import { ApiService } from "../Service/apiservices"
import React, { useContext, useEffect, useRef, useState } from "react";
import DataContext from "../Element/context";
import Skeleton from "react-loading-skeleton";
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { BrowserView, MobileView } from "react-device-detect";


const Footer = () => {
    const contextValues= useContext(DataContext)
    const didMountRef = useRef(true)
    const { settingData } = useContext(DataContext)
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [newsletteremail, setnewsletteremail] = useState("");
    const [footerData, setFooterData] = useState({})
    const [isloading, setisloading] = useState(false)
    const [footerData1, setFooterData1] = useState({})
    const [footerData2, setFooterData2] = useState({})
    const [footerData3, setFooterData3] = useState({})
    const [footerData4, setFooterData4] = useState({})
    const [footerData5, setFooterData5] = useState({})
    const [footerData6, setFooterData6] = useState({})
    useEffect(() => {
        if (didMountRef.current) {
            setisloading(true)
            ApiService.fetchData('footer').then(result => {
                if (result.status == 'success') {

                    setFooterData(result.footerIdData)
                    setFooterData1(result.footerIdData?.footer_desc1)
                    setFooterData2(result.footerIdData?.footer_desc2)
                    setFooterData3(result.footerIdData?.footer_desc3)
                    setFooterData4(result.footerIdData?.footer_desc4)
                    setFooterData5(result.footerIdData?.footer_desc5)
                    setFooterData6(result.footerIdData?.footer_desc6)
                    setTimeout(() => {
                        setisloading(false)
                    }, [500])

                }
                else {
                    setTimeout(() => {
                        setisloading(false)
                    }, [500])
                }

            }).catch(() => {
                setTimeout(() => {
                    setisloading(false)
                }, [500])
            })

        }
        didMountRef.current = false;
    }, []);

    const newsLetter = (e) => {
        setErrorMessage("");
        if (newsletteremail == "" || newsletteremail == null) {
            setErrorMessage('Please enter email address')
            setTimeout(() => {
                setErrorMessage('')
            }, [2000])
            return;
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletteremail)) {
            const email = newsletteremail.toLowerCase();
            let datastring = { newsletter_email: email };
            ApiService.postData('/newsletter-process', datastring).then((res) => {
                if (res?.status == "success") {
                    setSuccessMessage(res?.message)
                    setnewsletteremail("");
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, [2000])

                } else {
                    setErrorMessage(res?.message)
                    setnewsletteremail("");
                    setTimeout(() => {
                        setErrorMessage('')
                    }, [2000])
                }
            });
        } else {
            setErrorMessage("Invalid email address. Please enter valid email")
            setTimeout(() => {
                setErrorMessage('')
            }, [2000])
            return;
        }
    };

    return (<>
    <BrowserView> <footer className="footer">
            <div className="footerMiddle">
                <div className="container">
                    {isloading ? <>
                        <div className="row">
                            {[...Array(4)].map((item, index) => (
                                <div key={index} className="col-lg-3">
                                    <h2 className="footerTitle"><Skeleton width={150} /></h2>
                                    <div >
                                        <ul className="footerList">
                                            <li><a href="javascript:void(0)"><Skeleton width={100} /></a></li>
                                            <li><a href="javascript:void(0)"><Skeleton  width={100}/></a></li>
                                            <li><a href="javascript:void(0)"><Skeleton  width={100}/></a></li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </> : <>
                        <div className="row">
                            {footerData1 && ( <div className="col-lg-3">
                                <h2 className="footerTitle">{footerData.footer_title1}</h2>
                                <div dangerouslySetInnerHTML={{ __html: footerData1 }}></div>
                            </div>)}
                            {footerData2 && (  <div className="col-lg-3">
                                <h2 className="footerTitle">{footerData.footer_title2}</h2>
                                <div dangerouslySetInnerHTML={{ __html: footerData2 }}></div>
                            </div>) }
                            {footerData3 && (   <div className="col-lg-3">
                                <h2 className="footerTitle">{footerData.footer_title3}</h2>
                                <div dangerouslySetInnerHTML={{ __html: footerData3 }}></div>
                            </div>) }
                          
                           {footerData4 && ( <div className="col-lg-3">
                                <h2 className="footerTitle">{footerData.footer_title4}</h2>
                                <div dangerouslySetInnerHTML={{ __html: footerData4 }}></div>
                            </div>)}
                           
                        </div>
                    </>}

                </div>
            </div>
            <div className="footerBottom">
                <div className="container">
                    {isloading?<>
                        <div className="footerBottomLeft">
                        <p className="mb-0"><Skeleton width={100}></Skeleton></p>
                    </div>
                    <div className="footerBottomCenter tx-center">
                        <a href="javscript:void(0)" className="footer-logo"><Skeleton width={300} height={50}></Skeleton></a>
                    </div>
                    <div className="footerBottomRight justify-content-end">
                        <ul className="footerSocial">
                        <li><a href="javscript:void(0)" target='_blank'><Skeleton variant="circular"  ></Skeleton></a></li>
                        <li><a href="javscript:void(0)" target='_blank'><Skeleton variant="circular"  ></Skeleton></a></li>
                        <li><a href="javscript:void(0)" target='_blank'><Skeleton variant="circular"  ></Skeleton></a></li>
                        <li><a href="javscript:void(0)" target='_blank'><Skeleton variant="circular"  ></Skeleton></a></li>
                        </ul>
                    </div>
                    
                    </>:<>
                    <div className="footerBottomLeft">
                        <p className="mb-0">Copyright © 2024 Padmakshya India. All rights reserved.</p>
                    </div>
                    <div className="footerBottomCenter tx-center">
                        <a href="/" className="footer-logo"><img src={contextValues.settingData.white_logo ? contextValues.settingImageBaseUrl+contextValues.settingData.white_logo:'/img/logow.png'}></img></a>
                    </div>
                    <div className="footerBottomRight justify-content-end">
                        <ul className="footerSocial">
                            {settingData.instagram_url && (
                                <li><a href={settingData.instagram_url} target='_blank'><i className="ri-instagram-fill"></i></a></li>)}
                            {settingData.facebook_url && (
                                <li><a href={settingData.facebook_url} target='_blank'><i className="ri-facebook-circle-fill"></i></a></li>)}
                            {settingData.linkedin_url && (
                                <li><a href={settingData.linkedin_url} target='_blank'><i className="ri-linkedin-fill"></i></a></li>)}
                            {settingData.twitter_url && (
                                <li><a href={settingData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
                            {settingData.youtube_url && (
                                <li><a href={settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>)}
                        </ul>
                    </div>
                    </>}
                   
                </div>
            </div>
        </footer></BrowserView>
       


        <MobileView>

<footer className="footer">
    <div className="footerMiddle">
        <div className="container-fluid">
            <div className="row g-3">
             <div className="tx-center mb-20">
                <a href="/" className="footer-logo mb-20"><img src={contextValues.settingData.white_logo ? contextValues.settingImageBaseUrl+contextValues.settingData.white_logo:'/img/logow.png'} alt='Padmakshya'></img></a>
                <h3 className="footerTitle">FOLLOW US ON</h3>
                    <ul className="footerSocial">
                            {settingData.instagram_url && (
                                <li><a href={settingData.instagram_url} target='_blank'><i className="ri-instagram-fill"></i></a></li>)}
                            {settingData.facebook_url && (
                                <li><a href={settingData.facebook_url} target='_blank'><i className="ri-facebook-circle-fill"></i></a></li>)}
                            {settingData.linkedin_url && (
                                <li><a href={settingData.linkedin_url} target='_blank'><i className="ri-linkedin-fill"></i></a></li>)}
                            {settingData.twitter_url && (
                                <li><a href={settingData.twitter_url} target='_blank'><i className="ri-twitter-x-line"></i></a></li>)}
                            {settingData.youtube_url && (
                                <li><a href={settingData.youtube_url} target='_blank'><i className="ri-youtube-fill"></i></a></li>)}
                        </ul>
                </div>
                <div className="footeraccord">
                <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{footerData.footer_title1}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData1 }}>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">

                            <Accordion.Header>{footerData.footer_title2}</Accordion.Header>
                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData2 }}>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                        <Accordion.Header>{footerData.footer_title3}</Accordion.Header>
                        <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData3 }}>
                        </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                        <Accordion.Header>{footerData.footer_title4}</Accordion.Header>
                        <Accordion.Body dangerouslySetInnerHTML={{ __html: footerData4 }}>
                        </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                        </div>
               
                <div>
                  

                    {/* <p className="tx-14">Be the first to know about exciting new designs, special events, store openings and much more.</p> */}
                    <div dangerouslySetInnerHTML={{ __html: footerData6 }}></div>
                  
                </div>
            </div>
        </div>
    </div>
    <div className="footerBottom">
        <div className="container-fluid">
        <p className="mb-0 tx-center">Copyright © 2024 Padmakshya India. All rights reserved.</p>
        </div>
    </div>
</footer>
</MobileView>
    </>)
}

export default Footer