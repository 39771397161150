import { useContext } from "react"
import DataContext from "../Element/context"
import Modal from 'react-bootstrap/Modal';
import constant from "../Service/constant";
const SizeGuideModal = ({sizeguide,sizeGuideBaseUrl}) => {
  const contextValues = useContext(DataContext)
  const sizeguideModal = () => {
    contextValues.setToggleSizeguideModal(!contextValues.toggleSizeguideModal)
  }
  return (<>
    <Modal show={contextValues.toggleSizeguideModal} onHide={(e) => sizeguideModal()} >
      <button type="button" onClick={() => { sizeguideModal() }}><i className="ri-close-line ri-xl"></i></button>
      <div >
        <h4>{sizeguide.size_guide_name}</h4>
        <img src={sizeguide.size_guide_image ? sizeGuideBaseUrl+sizeguide.size_guide_image : constant.DEFAULT_IMAGE}/>
      </div>
    </Modal>
  </>)
}

export default SizeGuideModal