import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Service/apiservices";
import { useNavigate } from "react-router-dom";
import constant from "../../../Component/Service/constant";
import { BrowserView, MobileView } from "react-device-detect";
function BlogsHomeList() {
    const navigate = useNavigate()
    const didMountRef = useRef(true);
    const [blogsData, setBlogsData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');
    const [hoveredBlog, setHoveredBlog] = useState({});
    useEffect(() => {
        if (didMountRef.current) {
            ApiService.fetchData("featuredBlogsList").then((res) => {
                if (res.status === "success") {
                    setBlogsData(res.blogsData);
                    if(res.blogsData && res.blogsData.length > 0){
                        setHoveredBlog(res.blogsData[0])
                    }
                    setDefaultImage(res.blog_image_path)
                }
            });
        }
        didMountRef.current = false;
    }, []);
 
    return (
        <>
            <BrowserView>
                {blogsData && blogsData.length > 0 ?
                    <>
                        <div className="profile-title">Attention</div>
                        <div className="profile-container mb-40">
                            {blogsData.map((blogs) => (
                                <div className="profile-box" 
                                onClick={() => { navigate(`/blog/${blogs.blog_slug}`) }}
                                onMouseEnter={() => setHoveredBlog(blogs)}
                                onMouseLeave={() => setHoveredBlog(blogsData[0])}
                                >
                                    <img src={blogs.blog_image ? defaultImage + blogs.blog_image : constant.DEFAULT_IMAGE} alt={blogs.blog_name}></img>
                                </div>
                            ))}
                        </div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="section-title">
                                        <h2 className="mb-0">{hoveredBlog.blog_name}</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <p>{hoveredBlog.blog_short_description}</p>
                                    <a href={"/blog/"+hoveredBlog.blog_slug} className="btn btn-underline-primary p-0">Read More</a>
                                </div>
                            </div>
                        </div>
                    </>
                    : null}
            </BrowserView>
            <MobileView>
                {blogsData && blogsData.length > 0 ?
                     <>
                        <div className="profile-title">Attention</div>
                        <div className="profile-container mb-40">
                            {blogsData.map((blogs) => (
                                <div className="profile-box" onClick={() => { navigate(`/blog/${blogs.blog_slug}`) }}>
                                    <img src={blogs.blog_image ? defaultImage + blogs.blog_image : constant.DEFAULT_IMAGE} alt={blogs.blog_name}></img>
                                </div>
                            ))}
                        </div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="section-title">
                                        <h2 className="mb-0">Day to Night Divine With</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <p>Personification of Lakshmi as a busy eomen of today's<br></br>world-creating her own world.</p>
                                    <a href="/blog" className="btn btn-underline-primary p-0">Read More</a>
                                </div>
                            </div>
                        </div> 
                        </>
                    : null}
            </MobileView>
        </>
    );
}

export default BlogsHomeList;
