import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react"; 

function TeamList({ dataObj }) {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [teamsData, setTeamsData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');
    const [teamImagePath, setTeamImagePath] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            ApiService.fetchData("getTeamsData").then((res) => {
                if (res.status === "success") {
                    setTeamsData(res.resTeamData);
                    setDefaultImage(res.default_image_path)
                    setTeamImagePath(res.teamImagePath)
                    setSpinnerLoading(false);
                }
                setSpinnerLoading(false);
            });
        }
        didMountRef.current = false;
    }, []);

    return (
        <>
            {spinnerLoading ? (
                <div className="row">
                    {[...Array(3)].map((_, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="texmbox">
                                <div className="texmboxMedia">
                                    <Skeleton variant="text" width={300} height={250} />
                                </div>
                                <div className="texmboxContent">
                                    <h4>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </h4>
                                    <p>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                teamsData && teamsData.length > 0 ? (
                    Number(dataObj.home_brij_team_type) === 1 ? (
                        <div className="row">
                            {teamsData.map((value, index) => (
                                <div className={`col-lg-${dataObj.home_brij_team_value}`} key={index}>
                                    <div className="texmbox">
                                        <div className="texmboxMedia">
                                            <img src={value.team_image ? teamImagePath + value.team_image : defaultImage} alt={value.team_name} />
                                        </div>
                                        <div className="texmboxContent">
                                            {value.team_name && (<h4>{value.team_name}</h4>)}
                                            {value.team_desc && (
                                                <div dangerouslySetInnerHTML={{ __html: value.team_desc }}></div> )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Swiper
                            loop={true}
                            spaceBetween={15}
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            slidesPerView={dataObj.home_brij_team_value}
                        >
                            {teamsData.map((value, index) => (
                                <SwiperSlide key={index}> 
                                    <div className="texmbox">
                                        <div className="texmboxMedia">
                                            <img src={value.team_image ? teamImagePath + value.team_image : defaultImage} alt={value.team_name} />
                                        </div>
                                        <div className="texmboxContent">
                                            {value.team_name && (<h4>{value.team_name}</h4>)}
                                            {value.team_desc && (
                                                <div dangerouslySetInnerHTML={{ __html: value.team_desc }}></div> )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )
                ) : null
            )}
        </>
    );
}

export default TeamList;
