import Footer from "../../Component/Footer"
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../Component/Header"
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import constant from "../../Component/Service/constant";
import multiCurrency from "../../Component/Element/multiCurrency";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import { addToCart, addToCartSession } from "../../Component/Element/add_to_cart";
import DataContext from "../../Component/Element/context";

const Whishlist = () => {
    const didMountRef = useRef(true)
    const contextValues = useContext(DataContext)
    const navigate = useNavigate()
    const [listData, setListData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [spinnerloading, setspinnerloading] = useState('')
    const [ImgUrl, setImgurl] = useState('')
    const sessionToken = localStorage.getItem('USER_TOKEN')
    useEffect(() => {
        if (didMountRef.current) {
            getWishlistProduct()
        }
        didMountRef.current = false;
    }, []);
    const getWishlistProduct = () => {
        setisLoading(true)
        ApiService.fetchData('/user-fav-data').then((res) => {
            if (res.status == 'success') {
                setListData(res.favdata)
                setImgurl(res.img_path)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }

    const Removeaddtofav = (product_id) => {
        const dataString = {
            product_id: product_id,
        };
        ApiService.postData("remove-fav-wishlist", dataString).then((res) => {
            if (res.data.status == "success") {
                getWishlistProduct()
            }
        }).catch(() => {
        });
    };

    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product.product_id),
                product_name: addproduct.product.product_name,
                product_slug: addproduct.product.product_slug,
                product_image: addproduct.ufp_product_data.product_image ? addproduct.ufp_product_data.product_image : constant.DEFAULT_IMAGE,
                product_type: Number(addproduct.product.product_type),
                product_price: parseFloat(addproduct.product.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product.product_selling_price).toFixed(2),
                product_discount: addproduct.product.product_discount,
                product_variation: addproduct.ufp_product_data.product_variation,
                product_category_id: addproduct.product.product_category_id,
                selected_variation: addproduct.ufp_product_data.selected_variation,
                quantity: Number(1),
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    Removeaddtofav(addproduct.product.product_id)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    Removeaddtofav(addproduct.product.product_id)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const addToCartAllProcess = async () => {
        listData.forEach(async (item, index) => {
            if (parseFloat(item.product.product_selling_price) > 0) {
                const productData = {
                    product_id: Number(item.product.product_id),
                    product_name: item.product.product_name,
                    product_slug: item.product.product_slug,
                    product_image: item.ufp_product_data.product_image ? item.ufp_product_data.product_image : constant.DEFAULT_IMAGE,
                    product_type: Number(item.product.product_type),
                    product_price: parseFloat(item.product.product_price).toFixed(2),
                    product_selling_price: parseFloat(item.product.product_selling_price).toFixed(2),
                    product_discount: item.product.product_discount,
                    product_variation: item.ufp_product_data.product_variation,
                    product_category_id: item.product.product_category_id,
                    selected_variation: item.ufp_product_data.selected_variation,
                    quantity: Number(1),
                };
    
                contextValues.setSpinnerCubLoader(item.product_id);
    
                if (contextValues.userToken) {
                    const updateStatus = await addToCartSession(productData, 1, contextValues);
                    if (updateStatus) {
                        contextValues.setSpinnerCubLoader(0);
                        Removeaddtofav(item.product.product_id);
                    } else {
                        contextValues.setSpinnerCubLoader(0);
                    }
                } else {
                    const updateStatus = await addToCart(productData, 1, contextValues);
                    if (updateStatus) {
                        contextValues.setSpinnerCubLoader(0);
                        Removeaddtofav(item.product.product_id);
                    } else {
                        contextValues.setSpinnerCubLoader(0);
                    }
                }
            } else {
                toast.error('Internal Error, Please try later.');
            }
        });
    };
    

    return (<>
        <Header innerHeader={'innerHeader'}></Header>
        <BrowserView>
            {sessionToken == null && !sessionToken ? <>
                <section className="section-gap-medium tx-center bg-light01">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="noimg tx-center">
                                    <div className="section-title tx-center mb-40">
                                        <h2>My Wishlist</h2>
                                    </div>
                                    <p>You want to have all your favorite products with you?</p>
                                    <button className="btn btn-primary w-lg btn-lg mt-30 mb-20" type="button" onClick={() => { navigate('/register') }}>Register</button>
                                    <div>Already registered? <a className="btn btn-underline-primary p-0" href="/login">Login</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> :
                <>
                    {isLoading ?
                        <>
                            <section className="section-gap-medium bg-light01">
                                <div className="container">
                                    <div className="section-title tx-center mb-40">
                                        <h2><Skeleton width={'300px'} height={'50px'}></Skeleton></h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="whislistproductsec">
                                                {[...Array(6)].map((item, index) => {
                                                    return (<>
                                                        <div className="wpbox" key={index}>
                                                            <div className="wpboxMedia">
                                                                <Skeleton width={'160px'} height={'200px'}></Skeleton>
                                                            </div>
                                                            <div className="wpboxContent">
                                                                <div className="wpboxContentHeader mb-10">
                                                                    <h4 className="mb-0"><Skeleton width={'200px'}></Skeleton></h4>
                                                                    <div className="wpboxPrice tx-right">
                                                                        <ins className="new-price"><Skeleton width={'100px'}></Skeleton></ins>
                                                                        <del className="old-price"><Skeleton width={'100px'}></Skeleton></del>
                                                                    </div>
                                                                </div>
                                                                <ul className="wpboxMeta">
                                                                    <li><Skeleton width={'100px'}></Skeleton></li>
                                                                    <li><Skeleton width={'100px'}></Skeleton></li>
                                                                </ul>
                                                                <div className="wpboxContentFooter mt-30">
                                                                    <Skeleton width={'230px'} height={'50px'}></Skeleton>
                                                                    <Skeleton width={'30px'} height={'30px'}></Skeleton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </> :
                        <>
                            <section className="section-gap-medium bg-light01">
                                <div className="container">
                                    <div className="section-title tx-center mb-40">
                                        <h2>My Wishlist</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="whislistproductsec">
                                                {listData && listData.length > 0 ? <>
                                                    {listData.map((item, index) => {
                                                        return (<>
                                                            <div className="wpbox" key={index}>
                                                                <div className="wpboxMedia">
                                                                    <img src={item?.product?.product_image ? item?.product?.product_image : constant?.DEFAULT_IMAGE} alt={item?.product?.product_name} />
                                                                </div>
                                                                <div className="wpboxContent">
                                                                    <div className="wpboxContentHeader mb-10">
                                                                        <h4 className="mb-0">{item?.product?.product_name}</h4>
                                                                        <div className="wpboxPrice tx-right">
                                                                            {item?.product?.product_selling_price && <ins className="new-price">{multiCurrency(item?.product?.product_selling_price)}</ins>}
                                                                            {parseFloat(item?.product?.product_price) > parseFloat(item?.product?.product_selling_price) && <del className="old-price">{multiCurrency(item?.product?.product_price)}</del>}
                                                                        </div>
                                                                    </div>
                                                                    <ul className="wpboxMeta">
                                                                        {item.product.product_type == 1 && item.ufp_selected_variation && item.ufp_selected_variation.length > 0 ?
                                                                            <>
                                                                                {item.ufp_selected_variation.map((value, index) => {
                                                                                    return (<li key={index}>{value.attr}: {value.terms}</li>)
                                                                                })}
                                                                            </>
                                                                            : null}
                                                                        <li>Qty: 1</li>
                                                                    </ul>
                                                                    <div className="wpboxContentFooter mt-30">
                                                                        <button className="btn btn-primary w-lg btn-lg" type="button" onClick={(e) => addToCartProcess(item)}>Add to shopping bag</button>
                                                                        <button className="btn btn-transparent" onClick={(e) => Removeaddtofav(item.product.product_id)}><i className="ri-delete-bin-6-line ri-lg"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    })}
                                                    <div className="wpboxallproducts tx-center">
                                                        <p>Do you want topo purchase your entire wish list</p>
                                                        <button className="btn btn-primary w-lg btn-lg" type="button" onClick={(e) => addToCartAllProcess()}>Add to all shopping bag</button>
                                                    </div>
                                                </> :
                                                    <>
                                                        <div className='noimg'>
                                                            <img src='/img/empty-cart.webp' className='wd-150 mb-20'></img>
                                                            <h6>Your wishlist is empty!</h6>
                                                            <p className='tx-gray tx-14'>There is nothing in your wishlist. Let's add some items</p>
                                                            <a href='/' className='btn btn-primary w-lg btn-lg'><span>Start Shopping</span></a>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    }

                </>}
        </BrowserView>
        <MobileView>
            {!sessionToken && sessionToken == null ? <>
                <div className="noimg tx-center">
                    <div className="section-title tx-center mb-40">
                        <h2>My Wishlist</h2>
                    </div>
                    <p>You want to have all your favorite products with you?</p>
                    <button className="btn btn-primary w-lg btn-lg mt-30 mb-20" type="button" onClick={() => { navigate('/register') }}>Register</button>
                    <div>Already registered? <a className="btn btn-underline-primary p-0" href="/login">Login</a></div>
                </div>

            </> : <>
                {isLoading ? <>
                    <div className="whislistproductsec">
                        {[...Array(6)].map((item, index) => {
                            return (<>
                                <div className="wpbox" key={index}>
                                    <div className="wpboxMedia">
                                        <Skeleton width={'95px'} height={'115px'}></Skeleton>
                                    </div>
                                    <div className="wpboxContent">
                                        <div className="wpboxContentHeader mb-10">
                                            <h4 className="mb-0"><Skeleton width={'150px'}></Skeleton></h4>
                                            <div className="wpboxPrice tx-right">
                                                <ins className="new-price"><Skeleton width={'30px'}></Skeleton></ins>
                                                <del className="old-price"><Skeleton width={'30px'}></Skeleton></del>
                                            </div>
                                        </div>
                                        <ul className="wpboxMeta">
                                            <li><Skeleton width={'100px'}></Skeleton></li>
                                            <li><Skeleton width={'100px'}></Skeleton></li>
                                            <li><Skeleton width={'100px'}></Skeleton></li>
                                        </ul>
                                        <div className="wpboxContentFooter mt-30">
                                            <Skeleton width={'130px'} height={'30px'}></Skeleton>
                                            <Skeleton width={'40px'} height={'30px'}></Skeleton>
                                        </div>

                                    </div>
                                </div>
                            </>)
                        })}
                        <div className="wpboxallproducts tx-center">
                            <p>Do you want topo purchase your entire wish list</p>
                            <button className="btn btn-primary w-lg btn-lg" type="button">Add to all shopping bag</button>
                        </div>
                    </div>
                </> :
                    <>
                        {listData && listData.length > 0 ?
                            <>
                                <div className="whislistproductsec">
                                    {listData.map((item, index) => {
                                        return (<>
                                            <div className="wpbox" key={index}>
                                                <div className="wpboxMedia">
                                                    <img src={item?.product?.product_image ? item?.product?.product_image : constant?.DEFAULT_IMAGE} alt={item?.product?.product_name} />
                                                </div>
                                                <div className="wpboxContent">
                                                    <div className="wpboxContentHeader mb-0">
                                                        <h4 className="mb-0">{item?.product?.product_name}</h4>
                                                        <div className="wpboxPrice tx-right">
                                                            {item?.product?.product_selling_price && <ins className="new-price">{multiCurrency(item?.product?.product_selling_price)}</ins>}
                                                            {parseFloat(item?.product?.product_price) > parseFloat(item?.product?.product_selling_price) && <del className="old-price">{multiCurrency(item?.product?.product_price)}</del>}
                                                        </div>
                                                    </div>
                                                    <ul className="wpboxMeta">
                                                        <ul className="wpboxMeta">
                                                            {item.product.product_type == 1 && item.ufp_selected_variation && item.ufp_selected_variation.length > 0 ?
                                                                <>
                                                                    {item.ufp_selected_variation.map((value, index) => {
                                                                        return (<li key={index}>{value.attr}: {value.terms}</li>)
                                                                    })}
                                                                </>
                                                                : null}
                                                            <li>Qty: 1</li>
                                                        </ul>
                                                    </ul>
                                                    <div className="wpboxContentFooter mt-10">
                                                        <button className="btn btn-primary btn-sm" type="button" onClick={(e) => addToCartProcess(item)}>Add to shopping bag</button>
                                                        <button className="btn btn-transparent" onClick={() => Removeaddtofav(item.product.product_id)}><i className="ri-delete-bin-6-line "></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                    <div className="wpboxallproducts tx-center">
                                        <p>Do you want topo purchase your entire wish list</p>
                                        <button className="btn btn-primary w-lg btn-lg" type="button" onClick={(e) => addToCartAllProcess()}>Add to all shopping bag</button>
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className='noimg'>
                                    <img src='/img/empty-cart.webp' className='wd-150 mb-20'></img>
                                    <h6>Your wishlist is empty!</h6>
                                    <p className='tx-gray tx-14'>There is nothing in your wishlist. Let's add some items</p>
                                    <a href='/' className='btn btn-primary w-lg btn-lg'><span>Start Shopping</span></a>
                                </div>
                            </>
                        }
                    </>}
            </>}
        </MobileView>
        <Footer></Footer>

    </>)
}

export default Whishlist