import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import React, { useState } from 'react';
import moment from 'moment';
import { validEmail } from '../../../Component/Element/Regex';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../../Component/Service/apiservices';
const Calender = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [step, setstep] = useState(1);
    const [selectedschedule, setselectedschedule] = useState({})
    const [appointmentdetail, setappointmentdetail] = useState({
        user_name: '', user_email: '', user_note: ''
    })
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const navigate = useNavigate()
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleClick = (item) => {
        setSelectedIndex(item.index);
        setselectedschedule(item)
    };


    const generateAppointments = (startTime, numAppointments) => {
        const appointments = [];
        const currentTime = new Date();
    
        // Check if startTime is a Date object
        const startHour = startTime instanceof Date ? startTime.getHours() : parseInt(startTime.split(':')[0], 10);
        const startMinute = startTime instanceof Date ? startTime.getMinutes() : parseInt(startTime.split(':')[1], 10);
    
        let appointmentTime = new Date(selectedDate);
        appointmentTime.setHours(startHour);
        appointmentTime.setMinutes(startMinute);
        appointmentTime.setSeconds(0);
    
        for (let i = 1; i <= numAppointments; i++) {
            if (appointmentTime > currentTime || appointmentTime.getDate() !== currentTime.getDate()) {
                const hours = appointmentTime.getHours().toString().padStart(2, '0');
                const minutes = appointmentTime.getMinutes().toString().padStart(2, '0');
                const formattedTime = `${hours}:${minutes}`;
                appointments.push({
                    index: appointments.length + 1,
                    value: formattedTime
                });
            }
            
            appointmentTime.setHours(appointmentTime.getHours() + 1);
        }
    
        return appointments;
    };
    


    const handleappointChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setappointmentdetail(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const appointmentProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("loginRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            if (!validEmail.test(appointmentdetail.user_email)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }

            setButtonLoader(true)
            const dataString = {
                appointment_name: appointmentdetail.user_name,
                appointment_note: appointmentdetail.user_note,
                appointment_email: appointmentdetail.user_email,
                appointment_date: moment(selectedDate).format(`YYYY-MM-DD`),
                appointment_time: selectedschedule.value

            }
            ApiService.postData('bookanappointmentprocess', dataString).then((res) => {
                if (res.status == 'success') {
                    setButtonLoader(false)
                    setstep(3)
                    setTimeout(()=>{
                        setstep(1)
                    }, 3000)
                }
                else {
                    setButtonLoader(false)
                    setErrorMessage(res.message)
                }
            }).catch(() => {
                setButtonLoader(false)
            })

        }
    }

    const goBack = () => {
        setappointmentdetail({
            user_name: '', user_email: '', user_note: ''
        })
        setselectedschedule({ index: '', value: '' })
        setSelectedIndex(null)
        setstep(1)
    }

    const handleContinue = () => {
        if (selectedDate == '') {
            setErrorMessage('Please select the date for book the appointment')
            return false;
        }
        else if (selectedschedule == '' && selectedschedule == null) {
            setErrorMessage('Please select the time slot for book the appointment')
            return false;
        }
        else {
            setstep(2)
        }

    }
    // const currentTime = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });


    return (<>


        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
        {step == 1 ? <>
            <div className='calendarMain'>
                <div className='calendarSchedular'>
                    <div className='calendarSection'>
                        <Calendar onChange={handleDateChange}
                            value={selectedDate}
                            showNeighboringMonth={false}
                            minDate={new Date()}
                            calendarType="gregory"
                            className="appicalendar"
                        />

                    </div>
                    {generateAppointments( new Date(), 6).length > 0 && (
                        <div className='schedule_time_wrapper' >
                            {generateAppointments( new Date(), 6).map((item) => {
                                return (<>
                                    {item.index !== selectedIndex && (
                                        <div className="time_item" key={item.index} onClick={() => handleClick(item)}>
                                            <div className="item">{item.value}</div>
                                        </div>
                                    )}

                                    {item.index === selectedIndex && (
                                        <div className="time_item time_item_selected">
                                            <div className="item item_selected">{item.value}</div>
                                            <div className="chousen" onClick={() => { handleContinue() }}>Continue</div>
                                        </div>
                                    )}
                                </>)
                            })}
                        </div>


                    )}

                </div>
            </div>
        </> : step == 2 ? <>
            <div className='calendarMain'>
                <div className='scheduleForm'>
                    <button className="backbutton" onClick={() => { goBack() }}><i class="ri-arrow-left-s-line ri-xl"></i> Back</button>
                    <div className='scheduleFormInfo'>
                        <p className='mb-1'>1 hour</p>
                        <p className='mb-0'>{moment(selectedDate).format('MMMM DD')}, {selectedschedule.value}</p>
                    </div>
                    <div >
                        
                        <div className="form-group mb-15">
                            <input type="text" placeholder="Name" className="loginRequired" name='user_name' onChange={(e) => { handleappointChange(e) }} />
                        </div>
                        <div className="form-group mb-15">
                            <input type="email" placeholder="Email" className="loginRequired" name='user_email' onChange={(e) => { handleappointChange(e) }} />
                        </div>
                        <div className="form-group mb-15">
                            <textarea type="text" placeholder="Note" className="loginRequired" name='user_note' onChange={(e) => { handleappointChange(e) }} />
                        </div>
                        <button className="btn btn-lg btn-primary btn-full" disabled={buttonLoader} onClick={() => { appointmentProcess() }}>{buttonLoader? (<img src="/img/loder01.gif" width="60px" height="11px" />):'Confirm'} </button>
                    </div>
                </div>
            </div>

        </> : step == 3 ? <>
            <div className='section-title tx-center mb-40'>
                {/* <img src='/img/verified.gif' className="wd-200 mb-20"></img> */}
                <h2>Thank You for submitting</h2>
                <p>Your message has been sent. We will get back to you soon!</p>
                {/* <button className="btn btn-lg btn-primary btn-half mb-20 mt-20" onClick={() => { navigate('/') }}> Go to Home</button> */}

            </div>
        </> : null}
    </>)
}

export default Calender