import { BrowserView, MobileView } from "react-device-detect"
import constant from "../Service/constant"
import Skeleton from "react-loading-skeleton"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation, Scrollbar } from 'swiper/modules';
import React, { useRef,  useCallback } from "react";

const ProductGallery = ({ galleryImage, productname, loading }) => {
  const slidermobRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!slidermobRef.current) return;
    slidermobRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!slidermobRef.current) return;
    slidermobRef.current.swiper.slideNext();
  }, []);
  return (
    <>
     <BrowserView>
     {galleryImage && galleryImage?.length > 0 &&
         <div className="product-gallery">
           {galleryImage?.map((item, index) => {
             return (<>
               <img src={item ? item : constant.DEFAULT_IMAGE} key={index} alt={productname}></img>
             </>)
           })}

         </div>
     }
     </BrowserView>
       
     
      <MobileView>
        {galleryImage && galleryImage?.length > 0 &&
          <div className="gallerySlider"> 
          <Swiper
            ref={slidermobRef}
            spaceBetween={20}
            modules={[Navigation, Scrollbar,]}
            slidesPerView={1}
          >
            {galleryImage.map((item, index) => {
              return (

                <SwiperSlide>
                  <img src={item ? item : constant.DEFAULT_IMAGE} key={index} alt={productname}></img>
                </SwiperSlide>
              )
            }
            )}
          </Swiper>
            <div className='swiper-arrow'>
              <div className="prev-arrow" onClick={handlePrev}><img src='/img/leftarrow.svg' alt="Previous" /></div>
              <div className="next-arrow" onClick={handleNext}><img src='/img/rightarrow.svg' alt="Next" /></div>
            </div>
          </div>
        }
      </MobileView>

    </>)

}

export default ProductGallery