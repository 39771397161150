import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { ApiService } from '../Service/apiservices';
import DataContext from '../Element/context';
import { validEmail } from '../Element/Regex';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const AddressModal = ({ editAddDetails }) => {
    const navigate= useNavigate()
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_apartment:"",
        ua_default_address: "",
        ua_complete_address: '',
        ua_country_id: '',
        ua_address_type: "Home",
        ua_address_type_other: "",
    });
    const contextValues = useContext(DataContext);
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getCountryData();
        }
        didMountRef.current = false;
    }, []);

    const addressModal = () => {
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
    }
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [countryData, setcountryData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const onTodoAddChange = (e) => {
        setErrorMessage('')
        const { name, value } = e.target;
        setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (value !== '') {
            e.target.style.border = '';
        }
    }


    useEffect(() => {
        if (editAddDetails) {
            setUserAddressDetails({
                ua_id: editAddDetails.ua_id,
                ua_fname: editAddDetails.ua_fname,
                ua_lname: editAddDetails.ua_lname,
                ua_pincode: editAddDetails.ua_pincode,
                ua_complete_address: editAddDetails.ua_complete_address,
                ua_state_name: editAddDetails.ua_state_name,
                ua_city_name: editAddDetails.ua_city_name,
                ua_default_address: "",
                ua_country_id: editAddDetails.ua_country_id,
                ua_mobile:editAddDetails.ua_mobile,
                ua_address_type: editAddDetails.ua_address_type,
                ua_address_type_other: editAddDetails.ua_address_type_other,
                ua_apartment: editAddDetails.ua_apartment
            });
        } else {
            setUserAddressDetails({
                ua_id: 0,
                ua_fname: "",
                ua_lname: "",
                ua_mobile: "",
                ua_pincode: "",
                ua_state_name: "",
                ua_city_name: "",
                ua_default_address: "",
                ua_complete_address: '',
                ua_country_id: '',
                ua_address_type: "Home",
                ua_address_type_other: "",
                ua_apartment: "",
            });
        }
    }, [editAddDetails]);



    const getCountryData = () => {
        ApiService.fetchData("getCountryData").then((res) => {
            if (res.status == "success") {
                setcountryData(res.data);
            }
        });
    };

    const handleAddressProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {
                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (userAddressDetails.ua_country_id == '') {
                setErrorMessage('Please Select Country');
                return;
            }
            if (userAddressDetails.ua_fname == '') {
                setErrorMessage('Please Enter First Name');
                return;
            }
           
            if (userAddressDetails.ua_mobile == '') {
                setErrorMessage('Please Enter Mobile NUmber');
                return;
            }
            if (userAddressDetails.ua_apartment == '') {
                setErrorMessage('Please Enter  Appartment , Suite etc');
                return;
            }
            if (userAddressDetails.ua_complete_address == '') {
                setErrorMessage('Please Enter  Address');
                return;
            }
            if (userAddressDetails.ua_pincode == '') {
                setErrorMessage('Please enter post code');
                return;
            }
            if (userAddressDetails.ua_country_id === "") {
                toast.error("Please Select Country");
                return false;
            }
            if (userAddressDetails.ua_city_name == "") {
                setErrorMessage('Plese Enter City');
                return false;
            }

            if (userAddressDetails.ua_state_name == "") {
                setErrorMessage('Plese Enter State');
                return false;
            }
            
            if (userAddressDetails.ua_address_type === "") {
                toast.error("Please select Address type");
                return false;
            }

            if (userAddressDetails.ua_address_type === "Other") {
                if (userAddressDetails.ua_address_type_other === "") {
                    toast.error("Please enter Other");
                    return false;
                }
            }

            setspinnerLoading(true);
            ApiService.postData("/userAddressProcess", userAddressDetails).then(
                (res) => {
                    if (res.status == "success") {
                        setSuccessMessage(res.message);
                        setspinnerLoading(false);
                        window.location.reload();
                    } 
                    else if(res.message=='Session expired'){
                        localStorage.removeItem('USER_TOKEN')
                        setErrorMessage(res.message)
                        setTimeout(()=>{
                            window.location.href='/'
                        }, 500)
                    }
                    else {
                        setErrorMessage(res.message);
                        setspinnerLoading(false);
                    }
                }
            ).catch(() => { });
        }
    };
    return (<>
        <Modal className="addressModal" show={contextValues.toggleAddressModal} onHide={(e) => { addressModal() }}>
          
            <div className='addresssec'>
                <div className='addresssecHeader'>
                    <div>
                    <h5 className="mb-0 fw400">Add New Address</h5>
                        <p className='tx-14 mb-0'>Add your home and office addresses and enjoy faster checkout</p>
                    </div>
               
                    <button type="button" className="close" onClick={() => { addressModal() }}><i className="ri-close-line ri-xl"></i></button>
                </div>
                <div className='addresssecBody'>
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    {successMessage && (
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>
                    )}
                    <div className='row g-3'>
                        
                        <div className='col-lg-6 col-6'>
                            <div className='form-group'>
                                <label>First Name</label>
                                <input type='text' name="ua_fname" value={userAddressDetails.ua_fname}
                                    onChange={(e) => onTodoAddChange(e)}
                                    className='form-control required'
                                ></input>
                            </div>

                        </div>
                        <div className='col-lg-6 col-6'>
                            <div className='form-group'>
                                <label>Last Name</label>
                                <input type='text' name="ua_lname" value={userAddressDetails.ua_lname}
                                    onChange={(e) => onTodoAddChange(e)}
                                    className='form-control '
                                ></input>
                            </div>

                        </div>
                       
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Mobile Number</label>
                                <input type='number' name="ua_mobile" value={userAddressDetails.ua_mobile}
                                    className='form-control required'
                                    onChange={(e) => onTodoAddChange(e)}
                                ></input>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>House No, Appartment , Suite etc</label>
                                <input type='text' value={userAddressDetails.ua_apartment}
                                    onChange={(e) => onTodoAddChange(e)}
                                    className='form-control required'
                                    name="ua_apartment"></input>
                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='form-group'>
                                <label>Street Address </label>
                                <input type='text' value={userAddressDetails.ua_complete_address}
                                    className='form-control required'
                                    onChange={(e) => onTodoAddChange(e)}
                                    name="ua_complete_address"></input>
                            </div>
                        </div>
                        <div className='col-lg-4 col-6'>
                            <div className='form-group'>
                                <label>Postal Code</label>
                                <input
                                    type="number"
                                    name="ua_pincode"
                                    className="form-control ua_pincode required"
                                    value={userAddressDetails.ua_pincode}
                                    onChange={(e) => onTodoAddChange(e)}
                                    placeholder="Postal Code"
                                />

                            </div>
                        </div>
                        <div className='col-lg-4 col-6'>
                            <div className='form-group'>
                                <label>Country/Region</label>
                                <select name="ua_country_id" className="form-control required" value={userAddressDetails.ua_country_id} onChange={(e) => onTodoAddChange(e)}>
                                    <option value="">Select Country</option>
                                    {countryData.length > 0 && countryData.map((value) => (
                                        <option value={value.country_id}>{value.country_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 col-6'>
                            <div className='form-group'>
                                <label>State</label>
                                <input
                                    type="text"
                                    name="ua_state_name"
                                    className="form-control required"
                                    value={userAddressDetails.ua_state_name}
                                    onChange={(e) => onTodoAddChange(e)}
                                    placeholder="State"
                                />

                            </div>
                        </div>
                        <div className='col-lg-4 col-6'>
                            <div className='form-group'>
                                <label>City / District</label>
                                <input
                                    type="text"
                                    name="ua_city_name"
                                    className="form-control required"
                                    value={userAddressDetails.ua_city_name}
                                    onChange={(e) => onTodoAddChange(e)}
                                    placeholder="City"
                                />

                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                        <div className="form-group">
                            <label>Address Type</label>
                            <select name="ua_address_type" className="form-control required" value={userAddressDetails.ua_address_type}
                                onChange={(e) => onTodoAddChange(e)}>
                                <option value="">Address Type</option>
                                <option value="Home">Home</option>
                                <option value="Work">Work</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    {userAddressDetails.ua_address_type === 'Other' &&
                        <div className='col-lg-12 col-12'>
                            <div className="form-group">
                                <label>Other</label>
                                <input type="text" name="ua_address_type_other" className="form-control required"
                                    value={userAddressDetails.ua_address_type_other}
                                    onChange={(e) => onTodoAddChange(e)}
                                    placeholder="Other"
                                />
                            </div>
                        </div>
                    }
                     
                        <div className='col-lg-12'>
                            <button type="button" className="btn btn-primary btn-full mt-10"
                                onClick={(e) => { handleAddressProcess(e) }}
                            ><span>Save & Continue</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>

    </>)
}


export default AddressModal