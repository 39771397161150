import { useContext, useState, useEffect } from "react"
import DataContext from "../Element/context"
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const SearchModal = () => {
  const navigate = useNavigate()
  const [searchQuery, setsearchQuery] = useState('')
  const contextValues = useContext(DataContext)

  const searchModal = () => {
    contextValues.setToggleSearchModal(!contextValues.toggleSearchModal)
  }
  const onSearchBttnClick = () => {
    if (searchQuery) {
      if(searchQuery.length>=2){
        navigate(`/search-result/${searchQuery}`)
        searchModal()
      }
      else{
        toast.error('Please type atleast two character for better search') 
      }
    } else {
      toast.error('Please type something here to search')
    }
  }

  return (<>
    <Modal show={contextValues.toggleSearchModal} onHide={(e) => searchModal()} className="right searchModal">
      <button type="button" className="searchClose" onClick={() => { searchModal() }}><i className="ri-close-line ri-xl"></i></button>
      <div className="searchBox">
        <div className="searchBoxInner">
          <input type="text" placeholder="Search" onChange={(e) => {  setsearchQuery(e.target.value); }} value={searchQuery}></input>
          <button className="searchButton" type="button" onClick={() => { onSearchBttnClick() }}><i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
     
    </Modal>
  </>)
}

export default SearchModal