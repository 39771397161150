import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const ProductTabs = ({producttabs}) => {
    return (<>
    {producttabs && producttabs.length>0 ?<>
        <div className="productTabs">
            <Tabs
                defaultActiveKey={producttabs[0].tab_name}
                id="justify-tab-example"
            >
                {producttabs && producttabs?.map((item, index)=>(
                    item.tab_description&&(
                        <Tab eventKey={item.tab_name} title={item.tab_name} key={index}>
                        <div dangerouslySetInnerHTML={{__html:item.tab_description}}></div>
                    </Tab>
                    )
                ))}
            </Tabs>
        </div>
    </>:''}
    </>)
}
export default ProductTabs


