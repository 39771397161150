import Pagination from 'react-bootstrap/Pagination';
const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
    const pageItems = [];
  
    // Add previous and first page links
    if (currentPage > 1) {
      pageItems.push(<Pagination.First key="first" onClick={() => onPageChange(1)} />);
      pageItems.push(<Pagination.Prev key="prev" onClick={() => onPageChange(currentPage - 1)} />);
    }
  
    // Add page numbers
    for (let number = 1; number <= totalPages; number++) {
      pageItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  
    // Add next and last page links
    if (currentPage < totalPages) {
      pageItems.push(<Pagination.Next key="next" onClick={() => onPageChange(currentPage + 1)} />);
      pageItems.push(<Pagination.Last key="last" onClick={() => onPageChange(totalPages)} />);
    }
  
    return (
      <Pagination>
        {pageItems}
      </Pagination>
    );
  };
export default PaginationControls
  