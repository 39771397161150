import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../Service/apiservices";
import DataContext from "../Element/context";
import Modal from 'react-bootstrap/Modal';
const MobileMenu = () => {
    const contextValues = useContext(DataContext)
    let sessionToken = localStorage.getItem("USER_TOKEN")
    const [showHelpSubMenu, setShowHelpSubMenu] = useState(-1);
    const [showHelpFirstSubMenu, setShowHelpFirstSubMenu] = useState(-1);
    const [menuData, setmenuData] = useState([])

    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {

            getmenuList()
            const session_token= localStorage.getItem('USER_TOKEN')
            if(session_token && session_token!==null){
getUserdata()
            }
            
        }
        didMountRef.current = false;
    }, []);

    const getmenuList = () => {
        ApiService.fetchData('/menue-list').then(result => {
            if (result.status == 'success') {
                setmenuData(result.data)
            }

        }).catch((error) => { })
    }
   
    const MobileMenuModal = () => {
        contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
    }

    const toggleHelpSubMenu = (index) => {
        setShowHelpSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
    };
    const toggleHelpFirstSubMenu = (index) => {
        setShowHelpFirstSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
    };
 
    const getUserdata = () => {
        ApiService.fetchData('get-user-data').then((res) => {
            if (res.status == 'success') {
                contextValues.setRowUserData(res.rowUserData)
            }
            else if (res.status == 'error' && res.message=='Session expired') {
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }
        }).catch((error) => {

        })
    }
    const handleaccountmodal = () => {
        if (sessionToken && sessionToken !== '') {
            MobileMenuModal()
            navigate('/my-account')   
        }
        else {
            MobileMenuModal()
           navigate('/login')
        }
    }
    return (<>
        <Modal show={contextValues.toggleMenuModal} onHide={(e) => MobileMenuModal()} className="left menuModal">
            <div onClick={() => { handleaccountmodal() }}>
                <div className="msprofile d-flex align-items-center">
                    <div className="msprofile-media">
                        <img src='/img/user_default.png' alt={'user_img'}></img>
                    </div>
                    <div className="msprofile-content">
                        {sessionToken && contextValues.rowUserData.user_fname ? <>
                            <h6 className="mb-0 tx-14">Hi {contextValues.rowUserData.user_fname}!</h6>
                            <p className="tx-16 tx-color-02 mb-0">{contextValues.rowUserData.user_email}</p>
                        </> : <>
                            <h6 className="mb-0 tx-14">Hi Guest!</h6>
                            <p className="tx-13 tx-color-02 mb-0">Login / Register</p>
                        </>}
                    </div>
                    <div className="msprofile-arrow"><i className="ri-arrow-right-s-line ri-xl"></i></div>
                </div>
            </div>
            <div>
                <div className="sidemenu">
                    <ul>
                        {menuData.map((parent, index) => {
                            const hasChildren = parent.children && parent.children.length > 0;
                            const isSubMenuOpen = showHelpSubMenu === index;
                            return (
                                <li className={hasChildren ? 'sub-menu' : ''} key={parent?.menu_slug}>
                                    <div className='sub-menu-inner'    >
                                        {parent?.menu_categoryid > 0 ?
                                            <a href={"/list/category/" + parent?.categories?.cat_slug} >
                                                {parent?.menu_name}
                                            </a> : parent?.menu_collectionid > 0 ? <a href={"/list/collection/" + parent?.collection?.cat_slug} >
                                                {parent?.menu_name}
                                            </a> : parent?.menu_pageid > 0 ? <a href={"/" + parent?.pages?.page_url} >
                                                {parent?.menu_name}
                                            </a> : <a href={parent?.menu_customlink} >
                                                {parent?.menu_name}
                                            </a>}

                                        {hasChildren && (
                                            <div className='right' onClick={() => toggleHelpSubMenu(index)}>
                                                {/* <FontAwesomeIcon icon={isSubMenuOpen ? faCaretUp : faCaretDown} /> */}
                                                {isSubMenuOpen ? <i className="ri-arrow-right-s-line ri-xl" ></i> : <i className="ri-arrow-down-s-line ri-xl" ></i>}


                                            </div>
                                        )}
                                    </div>
                                    {hasChildren && isSubMenuOpen && (
                                        <ul>
                                            {parent?.children.map((firstChild, indexFirstChild) => {
                                                const hasFirstChildren = firstChild?.children && firstChild?.children?.length > 0;
                                                const isFirstSubMenuOpen = showHelpFirstSubMenu === indexFirstChild;
                                                if (firstChild?.menu_show_image !== 1) {
                                                    return (
                                                        <li className={hasFirstChildren ? 'sub-menu' : ''} key={firstChild?.menu_slug}>
                                                            <div className='sub-menu-inner'   >
                                                                {firstChild?.menu_categoryid > 0 ?
                                                                    <a href={"/list/category/" + firstChild?.categories?.cat_slug} >
                                                                        {firstChild?.menu_name}
                                                                    </a> : firstChild?.menu_collectionid ? <a href={"/list/collection/" + firstChild?.collection?.cat_slug} >
                                                                        {firstChild?.menu_name}
                                                                    </a> : firstChild?.menu_pageid > 0 ? <a href={"/" + firstChild?.pages?.page_url} >
                                                                        {firstChild?.menu_name}
                                                                    </a> : <a href={firstChild?.menu_customlink} >
                                                                        {firstChild?.menu_name}
                                                                    </a>}
                                                                {hasFirstChildren && (
                                                                    <div className='right' onClick={() => toggleHelpFirstSubMenu(indexFirstChild)}>
                                                                        {/* <FontAwesomeIcon icon={isFirstSubMenuOpen ? faCaretUp : faCaretDown} /> */}
                                                                        {isFirstSubMenuOpen ? <i className="ri-arrow-right-s-line ri-xl" ></i> : <i className="ri-arrow-down-s-line ri-xl" ></i>}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {hasFirstChildren && isFirstSubMenuOpen && (
                                                                <ul>
                                                                    {firstChild?.children.map((thirdChild, indexThirdChild) => (
                                                                        <li key={thirdChild?.cat_slug} >
                                                                            <div className='sub-menu-inner'    >
                                                                                {thirdChild?.menu_categoryid > 0 ?
                                                                                    <a href={"/list/category/" + thirdChild?.categories?.cat_slug} >
                                                                                        {thirdChild?.menu_name}
                                                                                    </a> : thirdChild?.menu_collectionid > 0 ? <a href={"/list/collection/" + thirdChild?.collection?.cat_slug} >
                                                                                        {thirdChild?.menu_name}
                                                                                    </a> :
                                                                                        thirdChild?.menu_pageid > 0 ? <a href={"/" + thirdChild?.pages?.page_url} >
                                                                                            {thirdChild?.menu_name}
                                                                                        </a> : <a href={thirdChild?.menu_customlink} >
                                                                                            {thirdChild?.menu_name}
                                                                                        </a>}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <li className={hasFirstChildren ? 'sub-menu' : ''} key={firstChild?.menu_slug}>
                                                            <div className='sub-menu-inner'   >
                                                                {firstChild?.menu_categoryid > 0 ?
                                                                    <a href={"/list/category/" + firstChild?.categories?.cat_slug} >
                                                                        {firstChild?.menu_name}
                                                                    </a> :
                                                                    firstChild?.menu_collectionid > 0 ? <a href={"/list/collection/" + firstChild?.collection?.cat_slug} >
                                                                        {firstChild?.menu_name}
                                                                    </a> :
                                                                        firstChild?.menu_pageid > 0 ? <a href={"/" + firstChild?.pages?.page_url} >
                                                                            {firstChild?.menu_name}
                                                                        </a> : <a href={firstChild?.menu_customlink} >
                                                                            {firstChild?.menu_name}
                                                                        </a>}
                                                                {hasFirstChildren && (
                                                                    <div className='right' onClick={() => toggleHelpFirstSubMenu(indexFirstChild)}>
                                                                        {/* <FontAwesomeIcon icon={isFirstSubMenuOpen ? faCaretUp : faCaretDown} /> */}
                                                                        {isFirstSubMenuOpen ? <i className="ri-arrow-right-s-line ri-xl" ></i> : <i className="ri-arrow-down-s-line ri-xl" ></i>}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {hasFirstChildren && isFirstSubMenuOpen && (
                                                                <ul>
                                                                    {firstChild.children.map((thirdChild, indexThirdChild) => (
                                                                        <li key={thirdChild?.cat_slug}>
                                                                            <div className='sub-menu-inner'    >
                                                                                {thirdChild?.menu_categoryid > 0 ?
                                                                                    <a href={"/list/category/" + thirdChild?.categories?.cat_slug} >
                                                                                        {thirdChild?.menu_name}
                                                                                    </a> : thirdChild?.menu_collectionid > 0 ?
                                                                                        <a href={"/list/collection/" + thirdChild?.collection?.cat_slug} >
                                                                                            {thirdChild?.menu_name}
                                                                                        </a> :

                                                                                        thirdChild?.menu_pageid > 0 ? <a href={"/" + thirdChild?.pages?.page_url} >
                                                                                            {thirdChild?.menu_name}
                                                                                        </a> : <a href={thirdChild?.menu_customlink} >
                                                                                            {thirdChild?.menu_name}
                                                                                        </a>}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Modal>



    </>)
}

export default MobileMenu