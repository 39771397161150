import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { ApiService } from '../../Component/Service/apiservices';
import { useNavigate, useParams } from "react-router-dom";
import DataContext from '../../Component/Element/context';
import Alert from 'react-bootstrap/Alert';
import { validPassword } from '../../Component/Element/Regex';
import React, { useContext, useState, useEffect, useRef } from 'react';



const ResetPassword = () => {
    const contextValues = useContext(DataContext)
    const { token } = useParams()
    const didMountRef = useRef(true)
    const [step, setStep] = useState(1)
    const [buttonLoader, setButtonLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [resetPasswordData, setResetPassword] = useState({
        user_password: "",
        user_confirm_password: ""
    })

    const forgetPasswordProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("passwordRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            // if (!validPassword.test(resetPasswordData.user_password)) {
            //     setErrorMessage('Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
            //     return false;
            // }
            if (resetPasswordData.user_confirm_password.trim() !== resetPasswordData.user_password.trim()) {
                setErrorMessage('The password and confirmation password does not match');
                return false;
            }
            const dataString = {
                user_password: resetPasswordData.user_password,
                user_confirm_password: resetPasswordData.user_confirm_password,
                token: token,
            }
            setButtonLoader(true)
            ApiService.loginProccessPostData("resetpasswordprocess", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setTimeout(() => {
                        setStep(2)
                        setButtonLoader(false)
                    }, 500)
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            })
        }
    }
    const handleForgetPasswordChange = (e) => {
        const { name, value } = e.target;
        setResetPassword(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };
    const navigate = useNavigate()
    return (<>
        <Header innerHeader={'innerHeader'}></Header>

        <section>
            <div className="container-fluid px-0">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/img/loginimg.jpg" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        {step == 1 ? <>
                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <h4>Reset password</h4>

                                </div>
                                {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                                {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                                <div className="form-group mb-15">
                                    <input type="password" placeholder="Password" className='passwordRequired' name='user_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                                </div>
                                <div className="form-group mb-15">
                                    <input type="password" placeholder="Confirm Password" className='passwordRequired' name='user_confirm_password' onChange={(e) => { handleForgetPasswordChange(e) }} />
                                </div>

                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={(e) => { forgetPasswordProcess() }} disabled={buttonLoader}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("CHANGE")}</button>
                            </div>
                        </> : step == 2 ? <>
                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <img src='/img/verified.gif' className="wd-200 mb-20" />
                                    <h2 style={{ textAlign: 'center' }}>Thank you for submitting </h2>
                                    <p style={{ textAlign: 'center' }}>Your Password has been reset</p>
                                </div>
                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}> Go to Home</button>
                                <div className="tx-center">
                                    <p>Already have an account?</p>
                                    <a className="btn btn-underline-primary p-0" href="/login">Sign in</a>
                                </div>
                            </div>

                        </> : null}

                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>

    </>)
}


export default ResetPassword