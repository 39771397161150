import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";




const OrderDetail=()=>{

    return(<>
    
   
        <BrowserView>
        <Header innerHeader={'innerHeader'}></Header>
            <div className="subheader ">
                <Container>
                    <Row>
                        <Col lg={12}>

                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/my-account">Overview</Breadcrumb.Item>
                                <Breadcrumb.Item active>Account Detail</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>

            </div>

            <section className="section-gap-medium">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <AccountSidebar></AccountSidebar>
                        </div>
                        <div className="col-lg-9">
                            <div className="section-title">
                                <h2>Order Detail</h2>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>

            <Footer></Footer>
        </BrowserView>
       
    </>)
}

export default OrderDetail