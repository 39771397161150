import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react"; 

function EventList({ dataObj }) {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [eventData, setEventData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');
    const [eventImagePath, setEventImagePath] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            ApiService.fetchData("getEventsData").then((res) => {
                if (res.status === "success") {
                    setEventData(res.resEventData);
                    setDefaultImage(res.default_image_path)
                    setEventImagePath(res.eventImagePath)
                    setSpinnerLoading(false);
                }
                setSpinnerLoading(false);
            });
        }
        didMountRef.current = false;
    }, []);

    return (
        <>
            {spinnerLoading ? (
                <div className="row">
                    {[...Array(3)].map((_, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="texmbox">
                                <div className="texmboxMedia">
                                    <Skeleton variant="text" width={300} height={250} />
                                </div>
                                <div className="texmboxContent">
                                    <h4>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </h4>
                                    <p>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                eventData && eventData.length > 0 ? (
                    Number(dataObj.home_brij_event_type) === 1 ? (
                        <div className="row justify-content-center">
                            {eventData.map((value, index) => (
                                <div className={`col-lg-${dataObj.home_brij_event_value}`} key={index}>
                                    <div className="vistibox">
                                        <div className="vistiboxMedia">
                                            <img src={value.event_image ? eventImagePath + value.event_image : defaultImage} alt={value.event_name} />
                                        </div>
                                        <div className="vistiboxContent">
                                            {value.event_name && (<h5>{value.event_name}</h5>)}
                                            {value.event_booth && (<ul> 
                                                <li>{value.event_booth}</li>
                                            </ul>)}
                                            <a href="/book-an-appointment" className="btn btn-primary mt-20"><span>Make an Appointment</span></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Swiper
                            loop={true}
                            spaceBetween={15}
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            slidesPerView={dataObj.home_brij_event_value}
                        >
                            {eventData.map((value, index) => (
                                <SwiperSlide key={index}> 
                                    <div className="vistibox">
                                        <div className="vistiboxMedia">
                                            <img src={value.event_image ? eventImagePath + value.event_image : defaultImage} alt={value.event_name} />
                                        </div>
                                        <div className="vistiboxContent">
                                            {value.event_name && (<h5>{value.event_name}</h5>)}
                                            {value.event_booth && (<ul> 
                                                <li>{value.event_booth}</li>
                                            </ul>)}
                                            <a href="/book-an-appointment" className="btn btn-primary mt-20"><span>Make an Appointment</span></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )
                ) : null
            )}
        </>
    );
}

export default EventList;
