import { useContext } from "react";
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import DataContext from "../../Component/Element/context";
const Checkout=()=>{
    const contextValues= useContext(DataContext)
    return(<>
    <div className="checkout-wapper">
        <div className="left">
            <div className="checkoutLogo">
                <a href="#">
                    <img src={contextValues.settingData.logo ?contextValues.settingImageBaseUrl+ contextValues.settingData.logo:'/img/logo.png'}></img>
                </a>
            </div>
            <ul className="checkoutStep">
                <li>
                    <a href="#">Address</a>
                </li>
                <li>
                    <a href="#">Payment</a>
                </li>
            </ul>
            <div className="row g-3">
                <div className="col-lg-12">
                <div className="flotting-group mb-10">
                    <p>Purchased before? <span className="text-underlined">Sign in</span></p>
                    
                </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group mb-10">
                        <input type="text"></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <button type="button" className="btn btn-primary btn-lg btn-full">CONTINUE TO PAY</button>
                </div>
            </div>
        </div>
        <div className="right">
            <div className="rightCheckoutPanel">
                <div className="checkoutProduct">
                    <div className="checkoutProductMedia">
                        <a href="#">
                            <img src="https://padmakshyajaipur.com/csadmin/public/img/uploads/media/5650381723027007.webp"></img>
                        </a>
                    </div>
                    <div className="checkoutProductContent">
                    <h2 className="title">
                        <a href="#">Sterling Silver Everything You Are Is Enough Locket Pendant</a>
                        </h2>
                        <div class="tx-gray-500 mb-10 tx-13">Metal: 18k Gold Vermeil</div>
                        <div className="checkoutProductFooter">
                         
                        <div class="checkoutProduct-price">
                            <ins class="new-price">$169.00</ins>
                            </div>
                            <div class="checkoutProductqty">
                                <span><i class="ri-subtract-line"></i></span>
                                <span>1</span>
                                <span><i class="ri-add-line"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="applyofferbox">
                    <input type="text" placeholder="Enter Coupons Code"></input>
                    <button className="applyofferButton btn-primary btn" type="button">APPLY</button>
                </div>
                <ul className="checkputPricebox">
                    <li>
                        <span>Shipping</span>
                        <span>$40</span>
                    </li>
                    <li>
                        <span>Subtotal (1 items)</span>
                        <span>$40</span>
                    </li>
                    <li>
                    <span className="tx-16 fw600">Total</span>
                        <span className="tx-16 fw600">$40</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    </>)
}

export default Checkout