import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../../Component/Header"
import Footer from "../../../Component/Footer"
import { ApiService } from "../../../Component/Service/apiservices"
import constant from "../../../Component/Service/constant";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
    const didMountRef = useRef(true)
    const navigate=useNavigate()
    const [blogsData, setblogData] = useState([])
    const [blogimgUrl, setblogimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            getblogsData()

        }
        didMountRef.current = false;
    }, []);
    const getblogsData = () => {
        setisLoading(true)
        ApiService.fetchData('/blog-list').then((res) => {
            if (res.status == 'success') {
                setblogimgUrl(res.blog_image_path)
                setblogData(res.blogsData)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    return (<>


        <Header innerHeader={'innerHeader'}></Header>
        {isLoading ?<>
            <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <p><Skeleton width={'200px'}></Skeleton></p>
                <h1><Skeleton width={'200px'}></Skeleton></h1>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    {[...Array(6)].map((_ , index)=>{
                        return(<>
                         <div className="col-lg-4">
                        <div className="blogList">
                            <div className="">
                                <div >
                                    <Skeleton width={'500px'} height={'500px'}></Skeleton>
                                </div>
                            </div>
                            <div className="blogListContent">
                                <h2 className="title"> <Skeleton width={'300px'} ></Skeleton></h2>
                                <p className="tdec"> <Skeleton width={'400px'}></Skeleton></p></div>
                            <a href="#" className="blog-link">
                            </a>
                        </div>
                    </div>
                        
                        
                        </>)
                    })}
                   
                </div>
            </div>
        </section>
        </>:<>
        {blogsData && blogsData.length>0 ?<> 
        <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <p>Découvrezs  nos articles</p>
                <h1>BLOG</h1>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    {blogsData.map((item , index)=>{
                        return(<>
                         <div className="col-lg-4" key={index}>
                        <div className="blogList" onClick={()=>{navigate(`/blog/${item.blog_slug}`)}}>
                            <div className="blogListMedia">
                            <div style={{ backgroundImage: `url(${blogimgUrl + item.blog_image})` }}>
                            </div>
                            </div>
                            <div className="blogListContent">
                                <h2 className="title">{item.blog_name}</h2>
                                <p className="tdec">{item.blog_short_description}</p></div>
                            <a href={`/blog/${item.blog_slug}`} className="blog-link">
                            </a>
                        </div>
                    </div>
                        
                        
                        </>)
                    })}
                   
                </div>
            </div>
        </section></>:null}
        </>}
       
        <Footer></Footer>

    </>)
}

export default Blogs