import { toast } from "react-toastify";
import { ApiService } from "../Service/apiservices";
import sessionCartData from "./cart_session_data";
const addToCart = (productData, quantity, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.selected_variation) === JSON.stringify(productData.selected_variation)
            );
        });
        if (existingProductIndex !== -1) {
            cartSession[existingProductIndex].quantity += quantity;
            quantity = cartSession[existingProductIndex].quantity
        } else {
            cartSession.push({ ...productData, quantity: 1 });
            quantity = 1
        }
        const dataString = {
            product_id: Number(productData.product_id),
            product_variation: productData.selected_variation,
            quantity: quantity,
        };
        ApiService.postData("addToCart", dataString).then((res) => {
            if (res.status === 'success') {
                localStorage.removeItem("COUPON_SESSION");
                contextValues.setCouponSession({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 })
                localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                const dataArray = sessionCartData();
                contextValues.setCartSummary(dataArray[3])
                toast.success(res.message);
                resolve(true);
            } else {
                toast.error(res.message);
                resolve(false);
            }
        }).catch((error) => {
            toast.error(error);
            resolve(false);
        });
    });
};

const addToCartSession = (productData, quantity, contextValues) => {
    return new Promise((resolve, reject) => {
        const existingProductIndex = contextValues.cartSessionData.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.selected_variation) === JSON.stringify(productData.selected_variation)
            );
        });
        if (existingProductIndex !== -1) {
            productData.quantity = contextValues.cartSessionData[existingProductIndex].quantity += Number(quantity);
        } else {
            productData.quantity = Number(quantity);
        }

        ApiService.postData("addToCartSession", productData).then((res) => {
            if (res.data.status === "success") {
                localStorage.removeItem("CART_SESSION");
                localStorage.removeItem("COUPON_SESSION");
                contextValues.setCouponSession({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 })
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                toast.success(res.data.message);
                resolve(true);
            } else {
                if (existingProductIndex !== -1) {
                    productData.quantity = contextValues.cartSessionData[existingProductIndex].quantity -= Number(quantity);
                } else {
                    productData.quantity = Number(quantity);
                }
                toast.error(res.data.message);
                resolve(false);
            }
        }).catch((error) => {
            toast.error(error);
            resolve(false);
        });
    });
};

const minusToCart = (productData, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.selected_variation) ===
                JSON.stringify(productData.selected_variation)
            );
        });

        if (existingProductIndex !== -1) {
            if (cartSession[existingProductIndex].quantity === 1) {
                cartSession.splice(existingProductIndex, 1);
            } else {
                cartSession[existingProductIndex].quantity -= 1;
            }
            localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
        }
        let cartSessionCount = localStorage.getItem("CART_SESSION");
        cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
        contextValues.setCartSessionData(cartSessionCount)
        contextValues.setCartCount(cartSessionCount.length)
        const dataArray = sessionCartData();
        contextValues.setCartSummary(dataArray[3])
        localStorage.removeItem("COUPON_SESSION");
        contextValues.setCouponSession({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 })
        resolve(true);
    });
};

const minusToCartSession = (productData, contextValues) => {
    return new Promise((resolve, reject) => {
        const dataString = {
            cart_id: Number(productData.cart_id),
            cart_user_id: Number(productData.cart_user_id)
        };
        ApiService.postData("minusToCartSession", dataString).then((res) => {
            if (res.data.status === "success") {
                localStorage.removeItem("CART_SESSION");
                localStorage.removeItem("COUPON_SESSION");
                contextValues.setCouponSession({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 })
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                toast.success(res.data.message);
                resolve(true);
            } else {
                toast.error(res.data.message);
                resolve(false);
            }
        }).catch((error) => {
            toast.error(error);
            resolve(false);
        });
    });
};

const removeToCart = (productData, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.selected_variation) ===
                JSON.stringify(productData.selected_variation)
            );
        });

        if (existingProductIndex !== -1) {
            cartSession.splice(existingProductIndex, 1);
            localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
        }
        let cartSessionCount = localStorage.getItem("CART_SESSION");
        cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
        contextValues.setCartSessionData(cartSessionCount)
        contextValues.setCartCount(cartSessionCount.length)
        const dataArray = sessionCartData();
        contextValues.setCartSummary(dataArray[3])
        localStorage.removeItem("COUPON_SESSION");
        contextValues.setCouponSession({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 })
        resolve(true);
    });
};

const removeToCartSession = (productData, contextValues) => {
    return new Promise((resolve, reject) => {
        const dataString = {
            cart_id: productData.cart_id,
            product_is_diamond: productData.product_is_diamond,
        };
        ApiService.postData("removeCartProduct", dataString).then((res) => {
            if (res.data.status === "success") {
                localStorage.removeItem("CART_SESSION");
                localStorage.removeItem("COUPON_SESSION");
                contextValues.setCouponSession({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 })
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                toast.success(res.data.message);
                resolve(true);
            } else {
                toast.error(res.data.message);
                resolve(false);
            }
        }).catch((error) => {
            toast.error(error);
            resolve(false);
        });
    });
};

export { addToCart, minusToCart, removeToCart, addToCartSession, minusToCartSession, removeToCartSession };