import { useContext, useEffect, useRef, useState } from "react";
import OrderSummary from "./compoment/order_summary";
import { useNavigate } from "react-router-dom";
import sessionCartData from "../../Component/Element/cart_session_data";
import DataContext from "../../Component/Element/context";
import { ApiService } from "../../Component/Service/apiservices";
import AddressFrom from "./compoment/address_form";
import AddressModal from "../../Component/Modal/address_modal";
import { toast } from "react-toastify";
import { validEmail } from "../../Component/Element/Regex";
const Address = () => {
    const didMountRef = useRef(true);
    const Navigate = useNavigate();
    const dataArray = sessionCartData();
    const contextValues = useContext(DataContext)
    const [addAddressSpinnerLoading, setAddAddressSpinnerLoading] = useState(false);
    const [hideShowLogin, setHideShowLogin] = useState(false);
    const [rowUserData, setRowUserData] = useState({});
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [loginSpinnerLoading, setLoginSpinnerLoading] = useState(false);
    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [userLoginDetails, setUserLoginDetails] = useState({
        user_email: "",
        user_password: "",
        session_data: dataArray[1],
        user_type: 1,
    });
    useEffect(() => {
        if (didMountRef.current) {
            contextValues.setAddressSession(dataArray[0])
            contextValues.setCouponSession(dataArray[2])
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
            if (localStorage.getItem("USER_TOKEN")) {
                getUserData()
                getUserAddress();
                getCartSessionData();
            } else {
                if (dataArray[1] && dataArray[1].length > 0) {
                    contextValues.setCartCount(dataArray[1].length)
                    contextValues.setCartSessionData(dataArray[1])
                    contextValues.setCartSummary(dataArray[3])
                } else {
                    Navigate("/");
                }
            }
            setTimeout(() => {
                setSpinnerLoading(false);
            }, 500);
        }
        didMountRef.current = false;
    }, [contextValues]);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }

    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                if (res.resAddressDefault && res.resAddressDefault.ua_id > 0) {
                    contextValues.setDefaultAddressStatus(true)
                } else {
                    contextValues.setDefaultAddressStatus(false)
                }
                contextValues.setUserAddressList(res.resUserAddress);
                setSpinnerLoading(false);
            } else {
                localStorage.removeItem("USER_TOKEN");
                setSpinnerLoading(false);
                Navigate("/");
            }
        });
    };

    const getUserData = () => {
        ApiService.fetchData("get-user-data").then((res) => {
            if (res.status == "success") {
                setRowUserData(res.rowUserData);
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ['ua_email']: res.rowUserData.user_email,
                }));
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    };

    const logoutUser = () => {
        const confirmed = window.confirm("Are you sure you want to log out");
        if (confirmed) {
            ApiService.fetchData("logout").then((res) => {
                localStorage.removeItem("USER_TOKEN")
                window.location.href='/'

            })
        }
    }

    const toggleCheckoutAddressModal = () => {
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
    }

    const selectAddress = (addressValue) => {
        const productData = {
            ua_id: addressValue.ua_id,
        };
        ApiService.postData("chooseAddress", productData).then((res) => {
            if (res.status === "success") {
                contextValues.setDefaultAddressStatus(true)
                contextValues.setUserAddressList(res.resUserAddress);
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            } else {
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }

    const onTodoChange = (e) => {
        const { name, value } = e.target;
        console.log("value",value);
        
        setUserLoginDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === 'user_email') {
            contextValues.setUserAddressDetails((prevState) => ({
                ...prevState,
                ['ua_email']: value,
            }));
        }
    };

    const handleContinueProcess = () => {
        /* if (parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) <= contextValues.settingData.admin_min_order) {
            toast.error("Minimum Order value $" + contextValues.settingData.admin_min_order);
            return false;
        } */
        if(contextValues.userToken == ''){
            if (userLoginDetails.user_email === '') {
                toast.error("Please enter Email Address");
                return false;
            }
            if (!validEmail.test(userLoginDetails.user_email)) {
                toast.error("Please enter valid Email Address");
                return false;
            }
        }
        
        if (contextValues.userAddressDetails.ua_fname === "") {
            toast.error("Please enter First Name");
            return false;
        }

        if (contextValues.userAddressDetails.ua_lname === "") {
            toast.error("Please enter Last Name");
            return false;
        }

        if (contextValues.userAddressDetails.ua_mobile == '') {
            toast.error("Please Enter Mobile NUmber");
            return;
        }
        if (contextValues.userAddressDetails.ua_apartment === "") {
            toast.error("Please enter Address (Villa/Apt#, Building Name & Street)");
            return false;
        }
        if (contextValues.userAddressDetails.ua_complete_address == '') {
            toast.error("Please Enter Address");
            return;
        }
        if (contextValues.userAddressDetails.ua_pincode === "") {
            toast.error("Please enter Pincode");
            return false;
        }
        if (contextValues.userAddressDetails.ua_country_id === "") {
            toast.error("Please Select Country");
            return false;
        } else {
            const selectedCountry = contextValues.countryCodeData.find(item => Number(item.country_id) === Number(contextValues.userAddressDetails.ua_country_id));
            if (selectedCountry) {
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ['ua_country_name']: selectedCountry.country_name,
                }));
            }
        }
        if (contextValues.userAddressDetails.ua_state_name === "") {
            toast.error("Please enter State Name");
            return false;
        }
        if (contextValues.userAddressDetails.ua_city_name === "") {
            toast.error("Please enter City Name");
            return false;
        }
        if (contextValues.userAddressDetails.ua_address_type === "") {
            toast.error("Please select Address type");
            return false;
        }
        if (contextValues.userAddressDetails.ua_address_type === "Other") {
            if (contextValues.userAddressDetails.ua_address_type_other === "") {
                toast.error("Please enter Other");
                return false;
            }
        }
        if (contextValues.userAddressList.length == 0) {
            setAddAddressSpinnerLoading(true)
            ApiService.postData("userAddressProcess", contextValues.userAddressDetails).then((res) => {
                if (res.status == "success") {
                    setTimeout(() => {
                        setAddAddressSpinnerLoading(false)
                        Navigate("/checkout/payment");
                    }, 500);
                } else {
                    setAddAddressSpinnerLoading(false)
                    toast.error(res.message);
                }
            });
        } else {
            const productData = {
                user_email: userLoginDetails.user_email,
                user_address: contextValues.userAddressDetails,
                shipping_amount: 0,
            };
            localStorage.setItem("GUEST_CHECKOUT", JSON.stringify(productData));
            Navigate("/checkout");
        }
    }

    const userLoginProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("loginRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }

        if (counter === 0) {
            if (!validEmail.test(userLoginDetails.user_email)) {
                toast.error("Please enter valid Email Id");
                return false;
            }
            if(hideShowLogin){
                if (userLoginDetails.user_password === '') {
                    toast.error("Please enter password");
                    return false;
                }
            }
            setLoginSpinnerLoading(true)
            ApiService.postData('logincheck', userLoginDetails).then((res) => {
                if (res.status === "success") {
                    localStorage.setItem("USER_TOKEN", res.user_token)
                    contextValues.setUserToken(res.user_token)
                    setLoginSpinnerLoading(false)
                    localStorage.removeItem("CART_SESSION");
                    getCartSessionData()
                    getUserAddress()
                    getUserData()
                } else {
                    toast.error(res.message)
                    setLoginSpinnerLoading(false)
                }
            });
        }
    };

    return (
        <>
            <div className="checkout-wapper">
                <div className="left">
                    <div className="checkoutLogo">
                        <a href="/">
                            <img src={contextValues.settingData.logo ?contextValues.settingImageBaseUrl+ contextValues.settingData.logo:'/img/logo.png'}></img>
                        </a>
                    </div>
                    <ul className="checkoutStep">
                        <li>
                            <a href="/address">Address</a>
                        </li>
                        <li>
                            <a href="javasctipt:void(0)">Payment</a>
                        </li>
                    </ul>
                    {contextValues.userToken ?
                        <div className="row g-3">
                            <div className="col-lg-12">
                                <div className="flotting-group mb-10">
                                    <p>Account <span className="text-underlined" onClick={(e) => logoutUser()}>Logout</span></p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                    <h5 className="mb-0">{rowUserData.user_fname}</h5>
                                    <p className="mb-0 tx-14">{rowUserData.user_email}</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row g-3">
                            <div className="col-lg-12">
                                <div className="flotting-group mb-10">
                                    <p>Purchased before? <span className="text-underlined" onClick={(e) => { setHideShowLogin(!hideShowLogin) }}>Sign in</span></p>
                                </div>
                            </div>
                            <div className={hideShowLogin ? "col-lg-5" : "col-lg-12"}>
                                <div className="flotting-group mb-10">
                                    <label className={`${emailFocused || userLoginDetails.user_email ? "label" : ""} `}>Email Address</label>
                                    <input
                                        type="text"
                                        placeholder="Email Address"
                                        name="user_email"
                                        value={userLoginDetails.user_email}
                                        onChange={(e) => onTodoChange(e)}
                                        onFocus={(e) => setEmailFocused(true)}
                                        onBlur={(e) => setEmailFocused(false)}></input>
                                </div>
                            </div>
                            {hideShowLogin &&
                                <>
                                    <div className="col-lg-5">
                                        <div className="flotting-group mb-10">
                                            <label className={passwordFocused || userLoginDetails.user_password ? "label" : ""}>Password</label>
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                name="user_password"
                                                value={userLoginDetails.user_password}
                                                onChange={(e) => onTodoChange(e)}
                                                onFocus={(e) => setPasswordFocused(true)}
                                                onBlur={(e) => setPasswordFocused(false)}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <button type="button" className="btn btn-primary btn-lg btn-full" onClick={(e) => userLoginProcess()}>Login</button>
                                    </div>
                                </>
                            }
                        </div>
                    }
                    {contextValues.userToken ?
                        <>
                            {/* Address Section */}
                            {contextValues.userAddressList.length > 0 ?
                                <>
                                    <div className="row g-3 mt-20">
                                        <div className="col-lg-12 ">
                                            <div className="cartSectionTitle">
                                                <h5 className="mb-0 tx-18">Shipping Details</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row g-3 mt-2">
                                                {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                                    <div className="col-lg-6">
                                                        <div className={`cartAddressbox ${value.ua_default_address == 1 ? 'active' : ''}`} key={index}
                                                            onClick={(e) => selectAddress(value)}>
                                                            <div className="radioBox">
                                                                <input type="radio" checked={value.ua_default_address == 1 ? true : false}></input>
                                                                <label for="radio-4" className="radio-label"></label>
                                                            </div>
                                                            <h6 className="mb-1 tx-14 fw400 tx-uppercase">{value.ua_fname} {value.ua_lname}</h6>
                                                            <p className="mb-0 tx-14">{value.ua_apartment}, {value.ua_complete_address}, {value.ua_city_name},</p>
                                                            <p className="mb-1 tx-14">{value.ua_state_name}, {value.ua_country_name} - {value.ua_pincode}</p>
                                                            <p className="tx-gray mb-0 tx-14">Mobile No: {value.ua_mobile}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" className="btn btn-primary-outline btn-medium d-flex align-items-center mb-50 mt-20" onClick={(e) => toggleCheckoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-primary btn-lg btn-full" onClick={() => { handleContinueProcess() }}>CONTINUE TO PAY</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <AddressFrom />
                                    <div className="row g-3">
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-primary btn-lg btn-full" onClick={() => { handleContinueProcess() }}>CONTINUE TO PAY</button>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                        :
                        <>
                            <AddressFrom />
                            <div className="row g-3">
                                <div className="col-lg-12">
                                    <button type="button" className="btn btn-primary btn-lg btn-full" onClick={() => { handleContinueProcess() }}>CONTINUE TO PAY</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <OrderSummary />
            </div>
            {contextValues.toggleAddressModal && <AddressModal editAddDetails={contextValues.userAddressDetails}></AddressModal>}
        </>
    )
}

export default Address