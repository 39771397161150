import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../Component/Element/context";
import { ApiService } from "../../../Component/Service/apiservices";
const AddressFrom = () => {
    const didMountRef = useRef(true);
    const Navigate = useNavigate();
    const contextValues = useContext(DataContext)

    const [firstnameFocused, setfirstnameFocused] = useState(false);
    const [lastnameFocused, setlastnameFocused] = useState(false);
    const [mobilenumberFocused, setmobilenumberFocused] = useState(false);
    const [addressFocused, setaddressFocused] = useState(false);
    const [postalFocused, setpostalFocused] = useState(false);
    const [stateFocused, setstateFocused] = useState(false);
    const [cityFocused, setcityFocused] = useState(false);
    const [countryFocused, setcountryFocused] = useState(false);
    const [addressTypeFocused, setaddressTypeFocused] = useState(false);
    const [addressTypeOtherFocused, setaddressTypeOtherFocused] = useState(false);

    const [billaddressFocused, setBilladdressFocused] = useState(false);
 
    useEffect(() => {
        if (didMountRef.current) {
            getCountryCodedata()
        }
        didMountRef.current = false;
    }, [contextValues]);

    const getCountryCodedata = () => {
        ApiService.fetchData('getcountrycode').then((res) => {
            if (res.status == 'success') {
                contextValues.setCountryCodeData(res.res_country_data)
            }
        }).catch((error) => {

        })
    }

    const onTodoRegChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        contextValues.setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
        if (name === 'ua_country_id' && newValue !== '') {
            const selectedCountry = contextValues.countryCodeData.find(item => item.country_id === newValue);
            if (selectedCountry) {
                contextValues.setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_country_id: newValue,
                    ua_country_name: selectedCountry.country_name,
                }));
            }
        }
    }

    

    return (
        <>
            <div className="row g-3 mb-20 mt-20">
                <div className="col-lg-12">
                    <div className="cartSectionTitle">
                        <h5 className="mb-0 tx-18">Shipping Details</h5>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="flotting-group mb-10">
                        <label className={`${firstnameFocused || contextValues.userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                        <input type="text" className={` required form-control`}
                            name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                            onFocus={(e) => setfirstnameFocused(true)}
                            onBlur={(e) => setfirstnameFocused(false)}
                        ></input>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="flotting-group mb-10">
                        <label className={lastnameFocused || contextValues.userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                        <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                            onFocus={(e) => setlastnameFocused(true)}
                            onBlur={(e) => setlastnameFocused(false)}
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group mb-10">
                        <label className={mobilenumberFocused || contextValues.userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                        <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                            onChange={(e) => onTodoRegChange(e)}
                            onFocus={(e) => setmobilenumberFocused(true)}
                            onBlur={(e) => setmobilenumberFocused(false)}></input>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="flotting-group mb-10">
                        <label className={billaddressFocused || contextValues.userAddressDetails.ua_apartment ? "label" : ""}>House No, Appartment , Suite etc</label>
                        <input type="text" className="required form-control" name="ua_apartment"
                            placeholder="House No, Appartment , Suite etc"
                            onFocus={(e) => setBilladdressFocused(true)}
                            onBlur={(e) => setBilladdressFocused(false)}
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="flotting-group mb-10">
                        <label className={addressFocused || contextValues.userAddressDetails.ua_complete_address ? "label" : ""}>Street Address</label>
                        <input type="text" className="required form-control" name="ua_complete_address"
                            placeholder="Street Address"
                            onFocus={(e) => setaddressFocused(true)}
                            onBlur={(e) => setaddressFocused(false)}
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label className={postalFocused || contextValues.userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                        <input type="number" className="required form-control"
                            placeholder="Postal Code" name="ua_pincode"
                            onFocus={(e) => setpostalFocused(true)}
                            onBlur={(e) => setpostalFocused(false)}
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label className={countryFocused || contextValues.userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                        <select onChange={(e) => { onTodoRegChange(e) }}
                            onFocus={(e) => setcountryFocused(true)}
                            onBlur={(e) => setcountryFocused(false)}
                            name='ua_country_id' value={contextValues.userAddressDetails.country_code} className='required form-control'>
                            <option value=''>Select Country</option>
                            {contextValues.countryCodeData && contextValues.countryCodeData.map((items, index) => {
                                return (<><option value={items.country_id} key={index}>{items.country_name}</option></>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label className={stateFocused || contextValues.userAddressDetails.ua_state_name ? "label" : ""}>State</label>
                        <input type="text" className="required form-control"
                            name="ua_state_name"
                            placeholder="State"
                            onFocus={(e) => setstateFocused(true)}
                            onBlur={(e) => setstateFocused(false)}
                            onChange={(e) => onTodoRegChange(e)}></input>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="flotting-group mb-10">
                        <label className={cityFocused || contextValues.userAddressDetails.ua_city_name ? "label" : ""}>City</label>
                        <input type="text" className=" required form-control" name="ua_city_name"
                            onChange={(e) => onTodoRegChange(e)}
                            placeholder="City"
                            onFocus={(e) => setcityFocused(true)}
                            onBlur={(e) => setcityFocused(false)}
                        ></input>
                    </div>
                </div>
                <div className={contextValues.userAddressDetails.ua_address_type === 'Other' ? "col-lg-6" : "col-lg-12"}>
                    <div className="flotting-group mb-10">
                        <label className={addressTypeFocused || contextValues.userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                        <select name="ua_address_type" className="form-control required" value={contextValues.userAddressDetails.ua_address_type}
                            onChange={(e) => onTodoRegChange(e)}
                            onFocus={(e) => setaddressTypeFocused(true)}
                            onBlur={(e) => setaddressTypeFocused(false)}>
                            <option value="">Address Type</option>
                            <option value="Home">Home</option>
                            <option value="Work">Work</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                {contextValues.userAddressDetails.ua_address_type === 'Other' &&
                    <div className='col-lg-6'>
                        <div className="flotting-group mb-10">
                            <label className={addressTypeOtherFocused || contextValues.userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                            <input type="text" name="ua_address_type_other" className="form-control required"
                                value={contextValues.userAddressDetails.ua_address_type_other}
                                onChange={(e) => onTodoRegChange(e)}
                                onFocus={(e) => setaddressTypeOtherFocused(true)}
                                onBlur={(e) => setaddressTypeOtherFocused(false)}
                                placeholder="Other"
                            />
                        </div>
                    </div>
                }
                
            </div>
        </>
    )
}

export default AddressFrom