import { useLocation, useNavigate } from "react-router-dom";
import React , {useRef, useEffect, useState, useContext} from 'react'
import { toast } from "react-toastify";
import { ApiService } from "../../Component/Service/apiservices";
import DataContext from "../../Component/Element/context";
const Accountsidebar=()=>{
const contextValues= useContext(DataContext)
const location = useLocation()
const didMountRef = useRef(true);
const navigate = useNavigate()
const [userData , setUserData] = useState({})
useEffect(() => {
    if (didMountRef.current) {
        getUserData()
    }
    didMountRef.current = false;
    }, []);
const logoutProcess = () => {
const confirmed = window.confirm("Are you sure ? Want to log out");
if (confirmed) {
ApiService.fetchData("logout").then((res) => {
if (res.status === "success") {
localStorage.removeItem("USER_TOKEN")
window.location.href='/'
}
})
}
}

const getUserData=()=>{
    ApiService.fetchData('get-user-data').then((res)=>{
        if(res.status=='success'){
            setUserData(res.rowUserData)
            contextValues.setUserData(res.rowUserData)
        }
        else if(res.status=='error' && res.message=='Session expired'){
            localStorage.removeItem('USER_TOKEN')
            window.location.href='/'
        }
    }).catch((error)=>{

    })
}



return(<>
<div className="account-sidebar">
<div className="account-user mb-3">
<div className="au-imag"><img src="/img/userr.png" /></div>
<a href="/account/profile">
<div className="au-content">
<h6 className="mb-0">Hi! {userData.user_fname}</h6>
<p className="mb-0">{userData.user_email}</p>
</div>
</a>
</div>
<div className="aclist mb-3">
<ul>
<li className={location.pathname=='/my-account' ? 'active':''}>
<a href="/my-account">Overview<i className="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/profile' ? 'active':''}>
<a href="/account/profile">Account Detail<i className="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/my-address' ? 'active':''}>
<a href="/account/my-address">Addresses<i className="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/my-order' ? 'active':''}>
<a href="/account/my-order">Order History<i className="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/wishlist' ? 'active':''}>
<a href="/account/wishlist">Wishlist<i className="ri-arrow-right-s-line"></i></a>
</li>
{/* <li className={location.pathname=='/help-support' ? 'active':''}>
<a href="/help-support">Help &amp; Support<i className="ri-arrow-right-s-line"></i></a>
</li> */}
</ul>
</div>
<div className="aclist">
<ul>
<li>
<a href="javascript:void(0)" className="pb-0 pt-0" onClick={()=>{logoutProcess()}}>Logout<i className="ri-arrow-right-s-line"></i></a>
</li>
</ul>
</div>
</div>

</>)
}
export default Accountsidebar