import Footer from "../../Component/Footer"
import Header from "../../Component/Header"



const WriteReview=()=>{
    return(<>
     <Header innerHeader={'innerHeader'}></Header>
    <div className="subHeader ">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="subHeaderContent">
                    <h1>Review</h1>
                    </div>
              </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
    
    </>)
}
export default WriteReview