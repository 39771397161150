import Header from "../../../Component/Header"
import Footer from "../../../Component/Footer"


const GiftCard=()=>{
    return(<>
            <Header innerHeader={'innerHeader'}></Header>
        <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <h2>Gift card</h2>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default GiftCard