import React, { createContext, useEffect, useState, useRef } from 'react';
import { ApiService } from '../Service/apiservices';
import sessionCartData from './cart_session_data';
const DataContext = createContext();
const dataArray = sessionCartData();
export const DataProvider = ({ children }) => {


  const [toggleSearchModal, setToggleSearchModal] = useState(false);
  const [productData, setProductData] = useState({});
  const [toggleSizeguideModal, setToggleSizeguideModal] = useState(false);
  const [toggleCartModal, setToggleCartModal] = useState(false);
  const [toggleAddressModal, setToggleAddressModal] = useState(false);
  const [toggleVariationModal, setToggleVariationModal] = useState(false);
  const [settingData, setSettingData] = useState({});
  const [favCount, setFavCount] = useState(0);
  const [rowUserData, setRowUserData] = useState({});
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem("USER_TOKEN"));
  const [countryCodeData, setCountryCodeData] = useState([])
  const [userData, setUserData] = useState(localStorage.getItem({}));
  const [cartSessionData, setCartSessionData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [spinnerCubLoader, setSpinnerCubLoader] = useState(0);
  const [couponSession, setCouponSession] = useState({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 });
  const [cartSummary, setCartSummary] = useState({});
  const [userAddressList, setUserAddressList] = useState([]);
  const [addressSession, setAddressSession] = useState({});
  const [defaultAddressStatus, setDefaultAddressStatus] = useState(false);
  const [userAddressDetails, setUserAddressDetails] = useState({
    ua_id: 0,
    ua_fname: "",
    ua_lname: "",
    ua_email: "",
    ua_mobile: "",
    ua_pincode: "",
    ua_state_name: "",
    ua_city_name: "",
    ua_apartment: "",
    ua_default_address: "",
    ua_complete_address: '',
    ua_country_id: '',
    ua_address_type: "",
    ua_address_type_other: "",
  });
  const didMountRef = useRef(true);


  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
      if (localStorage.getItem("USER_TOKEN")) {
        getCartSessionData()
        getFavCount()
      } else {
        setCartSessionData(dataArray[1])
        setCartCount(dataArray[1].length)
        setCartSummary(dataArray[3])
      }
    }
    didMountRef.current = false;
  }, [])

  const getCartSessionData = () => {
    const dataString = {
      coupon_session: localStorage.getItem("COUPON_SESSION"),
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        setCartSessionData(res.data.resCartData)
        setCartCount(res.data.resCartData.length)
        setCartSummary(res.data.cartSummary)
      } else {
        setCartSessionData([])
        setCartCount(0)
        setCartSummary({})
      }
    });
  }

  const getSettingData = () => {
    try {
      ApiService.fetchData('/settingsdata').then((res) => {
        if (res.status === 'success') {
          setSettingData(res.sitesettings)
          setSettingImageBaseUrl(res.setting_image_path)
        }
      })
    } catch (error) {
      console.error('An error occurred while fetching blog data:', error);
    }
  }


  const getFavCount = () => {
    ApiService.fetchData('getFavCount').then((res) => {
      if (res.count) {
        setFavCount(res?.count)
      }
      else {
        setFavCount(0)
      }
    })
  }


  return (
    <DataContext.Provider value={
      {
        settingData, setSettingData,
        settingImageBaseUrl, setSettingImageBaseUrl,
        userToken, setUserToken,
        toggleSearchModal, setToggleSearchModal,
        toggleSizeguideModal, setToggleSizeguideModal,
        toggleMenuModal, setToggleMenuModal,
        toggleCartModal, setToggleCartModal,
        rowUserData, setRowUserData,
        toggleVariationModal, setToggleVariationModal,
        userData, setUserData,
        toggleAddressModal, setToggleAddressModal,
        favCount, setFavCount,
        cartSessionData, setCartSessionData,
        cartCount, setCartCount,
        spinnerCubLoader, setSpinnerCubLoader,
        couponSession, setCouponSession,
        cartSummary, setCartSummary,
        productData, setProductData,
        userAddressList, setUserAddressList,
        addressSession, setAddressSession,
        defaultAddressStatus, setDefaultAddressStatus,
        userAddressDetails, setUserAddressDetails,
        countryCodeData, setCountryCodeData
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;