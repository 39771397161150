import { BrowserView, MobileView } from "react-device-detect"
import Header from "../../../Component/Header"
import Footer from "../../../Component/Footer"

const MaterialOfPadmakshya=()=>{
    return(<>
    
   
    <Header innerHeader={'innerHeader'}></Header>
    <section className="section-gap-medium tx-center">
            <div className="section-title tx-center">
                <h2>Material Of Padmakshya</h2>
            </div>
        </section>
   
    <Footer></Footer>
   
    </>)
}

export default MaterialOfPadmakshya