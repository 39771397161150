import { BrowserView, MobileView } from "react-device-detect"
import Header from "../../../Component/Header"
import Footer from "../../../Component/Footer"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import DataContext from "../../../Component/Element/context";
import JewleryCare from "../components/jewelery_care";


const JewelleryCare = () => {
    const navigate = useNavigate()
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({});
    const [headerImageUrl, setHeaderImageUrl] = useState("")
    const [jewelleryCareData, setjewelleryCareData] = useState([])

    useEffect(() => {
        if (didMountRef.current) {

            const getPageData = {
                slug: 'jewellery-care',
            };
            ApiService.postData("pagecontent", getPageData).then((res) => {
                if (res.status === "success") {
                    setPageData(res.data);
                    setSpinnerLoading(false)
                    setHeaderImageUrl(res.header_image_path)
                }
                else {
                    navigate('/error_404')
                    setSpinnerLoading(false)
                }
            }).catch((error) => { setSpinnerLoading(false) });


        }
        didMountRef.current = false;
    });
    return (<>
        <Header innerHeader={'innerHeader'}></Header>
        <BrowserView>

            {spinnerLoading ? <>
                <section className=" tx-center">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-10 ">
                                <Skeleton width={'1000px'} height={'500px'}></Skeleton>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-gap-medium tx-center">
                    <div className="section-title tx-center">
                        <h2> <Skeleton width={'300px'} ></Skeleton></h2>
                        <p> <Skeleton width={'400px'} ></Skeleton></p>
                    </div>
                </section>
                <section className='section-gap-md'>
                    <div className='container-fluid'>
                        <div className='row  '>
                            <div className='col-lg-4 '>

                                <ul className="careTablist">
                                    {Array.from({ length: 6 }).map((item, index) => {
                                        return (<>
                                            <Skeleton width={'300px'}></Skeleton>
                                        </>)
                                    })}

                                </ul>
                            </div>
                            <div className='col-lg-8 '>
                                {Array.from({ length: 3 }).map((item, index) => {
                                    return (<>
                                        <div className='mb-40' >
                                            <div className="row">
                                                <div className='section-title mb-20'>
                                                    <Skeleton width={'300px'} height={'40px'}></Skeleton>
                                                </div>
                                                <p ><Skeleton height={'20px'} width={'700px'}></Skeleton></p>
                                                <p ><Skeleton height={'20px'} width={'700px'}></Skeleton></p>
                                                <p ><Skeleton height={'20px'} width={'700px'}></Skeleton></p>
                                                <p ><Skeleton height={'20px'} width={'700px'}></Skeleton></p>
                                                <p ><Skeleton height={'20px'} width={'700px'}></Skeleton></p>

                                            </div>


                                        </div>
                                    </>)
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <section className='section-gap-md'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <a href='#'>
                                    <Skeleton height={'200px'}></Skeleton>
                                    <div className='section-title tx-center mb-40 mt-4'>
                                        <h3><Skeleton></Skeleton></h3>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a href='#'>
                                    <Skeleton height={'200px'}></Skeleton>
                                    <div className='section-title tx-center mb-40 mt-4'>
                                        <h3><Skeleton></Skeleton></h3>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a href='#'>
                                    <Skeleton height={'200px'}></Skeleton>
                                    <div className='section-title tx-center mb-40 mt-4'>
                                        <h3><Skeleton></Skeleton></h3>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            </> : <>
                <div style={{ backgroundColor: "#f5f3ef" }}>
                    {pageData.page_show_title == 1 || pageData.page_show_breadcrumb == 1 || pageData.page_header_image ?

                        <div className={`subHeader ${pageData.page_header_image ? 'subHeaderwithImg' : ''}`}>
                            {pageData.page_header_image && (<img src={headerImageUrl + pageData.page_header_image} className="img-fluid" alt={pageData.page_name} />)}
                            <div className="container">
                                <Row>
                                    <Col lg={12}>
                                        <div className={`${pageData.page_header_image ? 'subHeaderwithImgContent' : 'subHeaderContent'}`}>
                                            {pageData.page_show_title == 1 && (<h1>{pageData.page_name}</h1>)}
                                            {pageData.page_show_breadcrumb == 1 && (
                                                <Breadcrumb>
                                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                                    <Breadcrumb.Item active>
                                                        {pageData.page_name}
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>)
                                            }
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                        : null}
                    <section class="sectionbottom-gap-small">
                        <div class="container px-0">
                            <div class="row g-0 align-items-center justify-content-center">
                                <div class="col-lg-10">
                                    <div className="overlay-zoom"><img src='/img/jewell-banner.png' class="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="sectiontop-gap-small sectionbottom-gap-small ">
                        <div class="container">
                            <div class="row null align-items-center justify-content-center">
                                <div class="col-lg-6">
                                    <div>
                                        <div>
                                            <div class="section-title tx-center">
                                                <h3>Care For Your Jewellery</h3>
                                                <p>Alighieri’s Modern Heirlooms are designed to last a lifetime. These talismans are created to protect you on your adventures. Learn more about how to care for them, and preserve their power.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <JewleryCare></JewleryCare>
                </div>

            </>}


        </BrowserView>
        <MobileView>
            <section class=" sectionbottom-gap-small ">
                <div class="container-fluid px-0">
                    <div class="row g-0 align-items-center justify-content-center">
                        <div class="col-lg-12">
                            <a><img src='/img/jewell-banner.png' class="img-fluid" /></a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="sectiontop-gap-small sectionbottom-gap-small ">
                <div class="container-fluid">
                    <div class="row null align-items-center justify-content-center">
                        <div class="col-lg-12">
                            <div>
                                <div>
                                    <div class="section-title tx-center">
                                        <h3>Care For Your jewellery</h3>
                                        <p>
                                            Alighieri’s Modern Heirlooms are designed to last a lifetime. These talismans are created to protect you on<br />
                                            your adventures. Learn more about how to care for them, and preserve their power.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <JewleryCare></JewleryCare>

        </MobileView>

        <Footer></Footer>
    </>)
}

export default JewelleryCare