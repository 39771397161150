const ServiceFooter=()=>{
    return(<>
     <section className="section-gap-md bg-primary">
        <div className="container">
          <div className="section-title tx-center mb-40 ">
            <h2>Padmakshya Services</h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="iconVerticlebox tx-center">
                <img src="/img/delivery-man.png" className="mb-20"></img>
                <h5>Shipping Worldwide</h5>
                <p className="mb-0">In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="iconVerticlebox tx-center">
                <img src="/img/delivery-man.png" className="mb-20"></img>
                <h5>Unbeatable Craftsmanship</h5>
                <p className="mb-0">In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="iconVerticlebox tx-center">
                <img src="/img/delivery-man.png" className="mb-20"></img>
                <h5>Secure Payment Options</h5>
                <p className="mb-0">In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </>)
}


export default ServiceFooter