import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../../Component/Service/apiservices";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import constant from "../../../Component/Service/constant";
import { BrowserView, MobileView } from "react-device-detect";

function TestimonialsHomeList() {
    const navigate = useNavigate()
    const sliderRef = useRef(null);
    const didMountRef = useRef(true);
    const [testimonialData, setTestimonialData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            ApiService.fetchData("featuredTestimonialList").then((res) => {
                if (res.status === "success") {
                    setTestimonialData(res.testimonialData);
                    setDefaultImage(res.testimonial_image_path)
                }
            });
        }
        didMountRef.current = false;
    }, []);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <>
            <BrowserView>
                {testimonialData && testimonialData.length > 0 ?
                     
                                    <div className="testimonialSwiper">
                                        <Swiper
                                            ref={sliderRef}
                                            spaceBetween={20}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            slidesPerView={2}
                                        >
                                            {testimonialData.map((testimonial) => (
                                                <SwiperSlide key={testimonial.testimonial_id}>
                                                    <div className="testimonialbox">
                                                        <div className="testimonialboxMedia">
                                                            <img src={testimonial.testimonial_image ? defaultImage + testimonial.testimonial_image : constant.DEFAULT_IMAGE} alt={testimonial.testimonial_name} />
                                                        </div>
                                                        <div className="testimonialboxContent">
                                                            <div className="text" dangerouslySetInnerHTML={{ __html: testimonial.testimonial_desc }} />
                                                            <h6>{testimonial.testimonial_name}</h6>
                                                            <p className="mb-0">{testimonial.testimonial_location}</p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div className='swiper-arrow'>
                                            <div className="prev-arrow" onClick={handlePrev}><img src='/img/leftarrow.svg' alt="Previous" /></div>
                                            <div className="next-arrow" onClick={handleNext}><img src='/img/rightarrow.svg' alt="Next" /></div>
                                        </div>
                                    </div> 
                    : null}
            </BrowserView>
            <MobileView>
                {testimonialData && testimonialData.length > 0 ?
                    <section className="sectionbottom-gap-medium">
                        <div className="container">
                            <div className="section-title tx-center mb-40 ">
                                <h2>Customer Stories</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="testimonialSwiper">
                                        <Swiper
                                            ref={sliderRef}
                                            spaceBetween={20}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            slidesPerView={1}
                                        >
                                            {testimonialData.map((testimonial) => (
                                                <SwiperSlide key={testimonial.testimonial_id}>
                                                    <div className="testimonialbox">
                                                        <div className="testimonialboxMedia">
                                                            <img src={testimonial.testimonial_image ? defaultImage + testimonial.testimonial_image : constant.DEFAULT_IMAGE} alt={testimonial.testimonial_name} />
                                                        </div>
                                                        <div className="testimonialboxContent">
                                                            <div className="text" dangerouslySetInnerHTML={{ __html: testimonial.testimonial_desc }} />
                                                            <h6>{testimonial.testimonial_name}</h6>
                                                            <p className="mb-0">{testimonial.testimonial_location}</p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div className='swiper-arrow'>
                                            <div className="prev-arrow" onClick={handlePrev}><img src='/img/leftarrow.svg' alt="Previous" /></div>
                                            <div className="next-arrow" onClick={handleNext}><img src='/img/rightarrow.svg' alt="Next" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : null}
            </MobileView>
        </>
    );
}

export default TestimonialsHomeList;
