import { BrowserView, MobileView } from "react-device-detect"
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../../Component/Header"
import Footer from "../../../Component/Footer"
import { useNavigate } from "react-router-dom"
import { ApiService } from "../../../Component/Service/apiservices";
import constant from "../../../Component/Service/constant";

const Testimonial=()=>{
    const didMountRef = useRef(true)
    const navigate=useNavigate()
    const [testimonialData, settestimonialData] = useState([])
    const [testimonialimgUrl, settestimonialimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    useEffect(() => {
        if (didMountRef.current) {
            gettestimonialsData()

        }
        didMountRef.current = false;
    }, []);
    const gettestimonialsData = () => {
        setisLoading(true)
        ApiService.fetchData('/testimonial-list').then((res) => {
            if (res.status == 'success') {
                settestimonialimgUrl(res.testimonial_image_path)
                settestimonialData(res.testimonialData)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    return(<>
    
   
    <Header innerHeader={'innerHeader'}></Header>
    <section className="section-gap-medium tx-center">
        <div className="container">
            <div className="row">
                {testimonialData && testimonialData.length>0 ?<>
                {testimonialData.map((item , index)=>{
                    return(<>
                     <div className="col-4">
                        <img src={item.testimonial_image ? testimonialimgUrl+item.testimonial_image:constant.DEFAULT_IMAGE} style={{width:'300px'}}></img>
                        <h3>{item.testimonial_name}</h3>
                        <p dangerouslySetInnerHTML={{__html:item.testimonial_desc}}></p>
                     </div>
                    
                    </>)
                })}
                   
                </>:null}
               
            </div>
        </div>
   </section>
    <Footer></Footer>
    
    </>)
}

export default Testimonial