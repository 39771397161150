import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { ApiService } from "../../Component/Service/apiservices"
import React, { useContext, useState, useEffect, useRef } from 'react';
import { validPassword, validEmail, validNumber } from "../../Component/Element/Regex";
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import ServiceFooter from "../../Component/Footer/services_footer";


const Register = () => {
    const navigate = useNavigate()
    const [countryCodeData, setCountrycodeData] = useState([])
    const didMountRef = useRef(true);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [step, setStep] = useState(1);
    const [userRegDetails, setUserRegDetails] = useState({
        user_name: '',
        user_email: "",
        user_mobile: "",
        user_pass: '',
        country_code: 91,

    });
    useEffect(() => {
        if (didMountRef.current) {
            getCountrycodedata()
        }
        didMountRef.current = false
    }, []);

    const onTodoRegChange = (e) => {
        setErrorMessage('')
        const { name, value } = e.target;
        setUserRegDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    }
    const getCountrycodedata = () => {
        ApiService.fetchData('getcountrycode').then((res) => {
            if (res.status == 'success') {
                setCountrycodeData(res.res_country_data)
            }
        }).catch((error) => {

        })
    }


    const registerProcess = (e) => {

        let counter = 0;
        const myElements = document.getElementsByClassName("userRegisterRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            if (!validEmail.test(userRegDetails.user_email)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }

            if (!validNumber.test(userRegDetails.user_mobile)) {
                setErrorMessage("Phone Number must be in 10 Digits");
                return false;

            }

            //   if (!validPassword.test(userRegDetails.user_pass)) {
            //     setErrorMessage('Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
            //     return false;
            // }

            const dataString = {
                user_fname: userRegDetails.user_name,
                user_mobile: userRegDetails.user_mobile,
                user_email: userRegDetails.user_email,
                user_password: userRegDetails.user_pass,
                user_country_code: userRegDetails.country_code,

            };
            setButtonLoader(true)
            ApiService.loginProccessPostData("registerprocess", dataString).then((res) => {
                if (res.status === "success") {
                    setTimeout(() => {
                        setSuccessMessage(res.message)
                        setStep(2)
                        setButtonLoader(false)
                    }, 1000);
                } else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("USER_TOKEN");
                        setButtonLoader(false)
                    }, 500);
                } else if (res.status == 'error') {
                    setErrorMessage(res.message);

                    setButtonLoader(false)

                }
            }).catch((error) => {
                setErrorMessage('')
                setButtonLoader(false)
            });
        }

    }

    return (<>
        <Header innerHeader={'innerHeader'}></Header>
        <section>
            <div className="container-fluid px-0">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/img/loginimg.jpg" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        {step == 1 ? <>
                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <h4>REGISTER</h4>
                                    <p>Create your account with us, you can register here</p>
                                </div>
                                {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                                {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                                <div className="form-group mb-15">
                                    <input type="text" placeholder="Full Name" name="user_name" value={userRegDetails.user_name} className='userRegisterRequired' onChange={(e) => { onTodoRegChange(e) }} />
                                </div>
                                <div className="form-group mb-15">
                                    <input type="email" placeholder="Email Address" name="user_email" value={userRegDetails.user_email} className='userRegisterRequired' onChange={(e) => { onTodoRegChange(e) }} />
                                </div>
                                <div className="form-group country-input mb-15">
                                    <input type="number" placeholder="Mobile Number" name="user_mobile" value={userRegDetails.user_mobile} className='userRegisterRequired' onChange={(e) => { onTodoRegChange(e) }} />
                                    <select onChange={(e) => { onTodoRegChange(e) }} name='country_code' value={userRegDetails.country_code} className='countrycode userRegisterRequired'>
                                        {countryCodeData && countryCodeData.map((items, index) => {
                                            return (<><option value={items.country_phonecode} key={index}>+{items.country_phonecode}</option></>)
                                        })}
                                    </select>
                                    {/* <span className="countrycode">+91 <i className="ri-arrow-down-s-line"></i></span> */}
                                </div>
                                <div className="form-group mb-15">
                                    <input type="password" placeholder="Password" name='user_pass' value={userRegDetails.user_pass} className='userRegisterRequired' onChange={(e) => { onTodoRegChange(e) }} />
                                </div>
                                <p>We Will Send You Email To Verify Your Email Address</p>
                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={registerProcess}>{buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Register")}</button>
                                <div className="tx-center">
                                    <p>Already have an account?</p>
                                    <a className="btn btn-underline-primary p-0" href="/login">Sign in</a>
                                </div>

                            </div>

                        </> : step == 2 ? <>


                            <div className="loginrigisterbox">
                                <div className="tx-center mb-20">
                                    <img src="/img/verify_mail.png" className="wd-200 mb-20" />
                                    <p style={{ textAlign: 'center' }}>Congratulations! Your Account is created , Please verify Your email address, verification link has been sent to your registered email address.</p>
                                </div>

                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}>Go To Home</button>
                            </div></> : null}

                    </div>
                </div>
            </div>
        </section>
        <ServiceFooter></ServiceFooter>
        <Footer></Footer>
    </>)
}

export default Register