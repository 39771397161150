import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Service/apiservices";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react"; 

function AwardList({ dataObj }) {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [awardCertificate, setAwardCertificateData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');
    const [awardCertificateImagePath, setAwardCertificateImagePath] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            ApiService.fetchData("getAwardCertificateData").then((res) => {
                if (res.status === "success") {
                    setAwardCertificateData(res.resAwardCertificate);
                    setDefaultImage(res.default_image_path)
                    setAwardCertificateImagePath(res.awardCertificateImagePath)
                    setSpinnerLoading(false);
                }
                setSpinnerLoading(false);
            });
        }
        didMountRef.current = false;
    }, []);

    return (
        <>
            {spinnerLoading ? (
                <div className="row">
                    {[...Array(3)].map((_, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="texmbox">
                                <div className="texmboxMedia">
                                    <Skeleton variant="text" width={300} height={250} />
                                </div>
                                <div className="texmboxContent">
                                    <h4>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </h4>
                                    <p>
                                        <Skeleton variant="text" width={300} height={50} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                awardCertificate && awardCertificate.length > 0 ? (
                    Number(dataObj.home_brij_award_type) === 1 ? (
                        <div className="row justify-content-center">
                            {awardCertificate.map((value, index) => (
                                <div className={`col-lg-${dataObj.home_brij_award_value}`} key={index}>
                            
                                        <div className="awardbox">
                                            <div className="awardboxMedia">
                                                <img src={value.certificate_image ? awardCertificateImagePath + value.certificate_image : defaultImage} alt={value.certificate_name} />
                                            </div>
                                            {value.certificate_name && (<h5>{value.certificate_name}</h5>)}
                                            {value.certificate_desc && Number(dataObj.home_brij_award_display_desc) == 1 && (
                                                <div dangerouslySetInnerHTML={{ __html: value.certificate_desc }}></div> )} 
                                        </div>  
                                  
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Swiper
                            loop={true}
                            spaceBetween={15}
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            slidesPerView={dataObj.home_brij_award_value}
                        >
                            {awardCertificate.map((value, index) => (
                                <SwiperSlide key={index}> 
                                   
                                        <div className="awardbox">
                                            <div className="awardboxMedia">
                                                <img src={value.certificate_image ? awardCertificateImagePath + value.certificate_image : defaultImage} alt={value.certificate_name} />
                                            </div>
                                            {value.certificate_name && (<h5>{value.certificate_name}</h5>)}
                                            {value.certificate_desc && Number(dataObj.home_brij_award_display_desc) == 1 && (
                                                <div dangerouslySetInnerHTML={{ __html: value.certificate_desc }}></div> )} 
                                        </div>  
                                    
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )
                ) : null
            )}
        </>
    );
}

export default AwardList;
