import { useState, useEffect } from "react"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { useParams } from "react-router-dom"
import { ApiService } from "../../Component/Service/apiservices"
import ProductBox from "../../Component/Element/product_box"
import Skeleton from "react-loading-skeleton"


const SearchResult = () => {
    const { slug } = useParams()
    const [searchResultData, setsearchResultData] = useState([])
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        if (slug.length >= 2) {
            getSearchData();
        }
    }, [slug]);

    const getSearchData = () => {
        setisLoading(true)
        const dataString = {
            search_filter: slug,
        }
        ApiService.postData("getsearchresultdata", dataString).then((res) => {
            if (res.status === "success") {
                setsearchResultData(res.data.data);
                setisLoading(false)
            } else {
                setisLoading(false)
            }
        });
    }

    return (<>
        <Header innerHeader={'innerHeader'}> </Header>
        {isLoading ? <>
            <section className="section-gap-sm">
                <div className="container-fluid">
                    <div className="row">
                        {[...Array(8)].map((_, index) => {
                            return (
                                <div className="col-lg-3" key={index}>
                                <div className="product" >
                                    <a href="javascript:void(0)" className='product-media-shap'>
                                        <figure className='product-media'>
                                            <Skeleton variant="rectangular" width={700} height={800} />
                                        </figure>
                                        <div className='product-action-vertical'>
                                            <a href="javascript:void(0)" className="btn-product-icon btn-wishlist" title="Add to wishlist">
                                            <Skeleton variant="rectangular" width={20} height={20} />
                                            </a>
                                        </div>
                                        <div className='product-label'>
                                            <span className='label-new'><Skeleton variant="rectangular" width={30} height={10} /></span>
                                        </div>
                                        <button className='addcartbutton'><i className="ri-add-line"></i></button>
                                    </a>
                                    <div className='product-details'>
                                        <h2 className="product-name"> <a href="javascript:void(0)"><Skeleton variant="rectangular" width={200} height={20} /></a></h2>
                                        <div className="product-price">
                                            <ins className="new-price"><Skeleton variant="rectangular" width={60} height={20} /></ins>
                                            <del className="old-price"><Skeleton variant="rectangular" width={60} height={20} /></del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </> : <>
            {searchResultData && searchResultData?.length > 0 ? <>
                <section className="section-gap-sm">
                    <div className="container-fluid">
                        <div className="row">
                            {searchResultData.map((subvalue, indexProduct) => {
                                return (
                                    <div className="col-lg-3">
                                        <ProductBox productValue={subvalue} classType="product" key={indexProduct}></ProductBox>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>

            </> : <div className='noimg'>
                <img src='/img/noproduct.png'></img>
                <h4 className='mt-40'>No Search Result Found</h4>
            </div>}


        </>}


        <Footer></Footer>
    </>)
}

export default SearchResult