import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import ProductBox from "../../Component/Element/product_box"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import { Navigation, A11y } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import { useParams } from 'react-router-dom';
import constant from "../../Component/Service/constant";
import Loader from "react-js-loader";
import PaginationControls from './pagination_controls';
import Skeleton from "react-loading-skeleton"; 
const ProductList = () => {
    const { type, slug } = useParams();
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    const [categoryList, setcategoryList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [selectedSort, setSelectedSort] = useState('random');
    const [categoryImgUrl, setCategoryImgUrl] = useState('');
    const [collectionImgUrl, setCollectionImgUrl] = useState('');
    const [resProductData, setProductData] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    const [collectionList, setCollectionList] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [headerdetail, setheaderdetail] = useState()
    const [attributesData, setAttributesData] = useState([])
    const [fieldShowStatus, setFieldShowStatus] = useState({
        filtershow: '',
        breadcrumbshow: '',
        type: ''
    })

    useEffect(() => {
        if (didMountRef.current) {
            getCategoryDetail()
        }
        didMountRef.current = false;
    })

    useEffect(() => {
        getProductListing(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
    };

    const getProductListing = (page) => {
        const dataString = {
            filterArray: selectedAttributes,
            sort: selectedSort,
            slug: slug ? slug : '',
            type: slug === "all" ? '' : type,
        };
        ApiService.postData(`product-list?page=${page}`, dataString).then((res) => {
            if (res.status == "success") {
                setProductData(res.resProductsData.data);
                setCategoriesList(res.categoriesList);
                setCollectionList(res.collectionList);
                setCategoryImgUrl(res.category_img_url);
                setCollectionImgUrl(res.collection_img_url);
                setTotalPages(res.resProductsData.last_page);
                setSpinnerLoading(false)
            } else {
                setSpinnerLoading(false)
            }
        }).catch(() => { setSpinnerLoading(false) })
    }

    const getProductListingSelect = (selectedAttributes, selectedSort) => {
        const dataString = {
            filterArray: selectedAttributes,
            sort: selectedSort,
            slug: slug ? slug : '',
            type: slug === "all" ? '' : type,
        };
        setSpinnerLoading(true)
        ApiService.postData("product-list?page=1", dataString).then((res) => {
            if (res.status == "success") {
                setProductData(res.resProductsData.data);
                setTotalPages(res.resProductsData.last_page);
                setSpinnerLoading(false)
            } else {
                setSpinnerLoading(false)
            }
        }).catch(() => { setSpinnerLoading(false) })
    }

    const getCategoryDetail = () => {
        const dataString = {
            type: type,
            cat_slug: slug,
        };
        ApiService.postData("categorydetails", dataString).then((res) => {
            if (res.status === "success") {
                setheaderdetail(res.aryHeaderDetails)
                setAttributesData(res.resAttributesData)
                gefieldshowstatus(res.themesettings)
            }
        }).catch(() => { })
    }

    const gefieldshowstatus = (themeSetting) => {
        if (type == 'category') {
            setFieldShowStatus({
                filtershow: themeSetting.ts_category_show_filter,
                breadcrumbshow: themeSetting.ts_category_show_breadcrumb,
                type: 'Category'
            })
        } else if (type == 'collection') {
            setFieldShowStatus({
                filtershow: themeSetting.ts_collection_show_filter,
                breadcrumbshow: themeSetting.ts_collection_show_breadcrumb,
                type: 'Collection'
            })
        } else if (type == 'shop-all') {
            setFieldShowStatus({
                filtershow: themeSetting.ts_page_show_filter,
                breadcrumbshow: themeSetting.ts_page_show_breadcrumb,
                type: 'Shop All'
            })
        }
    }

    return (<>
        <Header></Header>
        <div className="subHeader subHeaderwithImg">
            {headerdetail && headerdetail.showheaderImg == 1 &&
                <div className="subHeaderbg">
                    <img src={headerdetail.imageurl}></img>
                </div>
            }
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="subHeaderContent">
                            {fieldShowStatus.breadcrumbshow == 1 ? (
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="javascript:void(0)">{fieldShowStatus.type}</Breadcrumb.Item>
                                    {type !== 'shop-all' ? (<Breadcrumb.Item active>{headerdetail && headerdetail.title ? headerdetail.title : ''}</Breadcrumb.Item>) : ''}
                                </Breadcrumb>
                            ) : null}
                            {headerdetail && headerdetail.showTitle == 1 ? <h1>{headerdetail.title}</h1> : ''}
                            {headerdetail && headerdetail.showDescr == 1 ? <p dangerouslySetInnerHTML={{ __html: headerdetail.desc }}></p> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {type === 'category' && categoriesList.length > 0 ?
            <section className="section-gap-sm">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Swiper
                                spaceBetween={20}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Navigation, A11y, Autoplay]}
                                slidesPerView={5}
                            >
                                {categoriesList.map((subvalue, indexProduct) => (
                                    <SwiperSlide key={indexProduct}>
                                        <a href={"/list/category/" + subvalue.cat_slug} className="slideCatbox">
                                            <div className="slideCatboxMedia">
                                                <img src={subvalue.cat_image !== '' && subvalue.cat_image !== null ? categoryImgUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt={subvalue.cat_name} />
                                            </div>
                                            <div className="slideCatboxContent">
                                                <h6 className="mb-0">{subvalue.cat_name}</h6>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                ))
                                }
                            </Swiper>
                        </div>

                    </div>
                </div>
            </section>
            : type === 'collection' && collectionList.length > 0 ?
                <section className="section-gap-sm">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <Swiper
                                    spaceBetween={20}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Navigation, A11y, Autoplay]}
                                    slidesPerView={5}
                                >
                                    {collectionList.map((subvalue, indexProduct) => (
                                        <SwiperSlide key={indexProduct}>
                                            <a href={"/list/collection/" + subvalue.cat_slug} className="slideCatbox">
                                                <div className="slideCatboxMedia">
                                                    <img src={subvalue.cat_image !== '' && subvalue.cat_image !== null ? collectionImgUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt={subvalue.cat_name} />
                                                </div>
                                                <div className="slideCatboxContent">
                                                    <h6 className="mb-0">{subvalue.cat_name}</h6>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    ))
                                    }
                                </Swiper>
                            </div>

                        </div>
                    </div>
                </section>
                : null
        }
        {spinnerLoading ?
            <section className="section-gap-sm">
                <div className="container-fluid">
                    <div className="row">
                        {Array.from({ length: 4 }).map((_, index) => (
                        <div className="col-lg-3">
                            <div className="product" >
                                <a href="javascript:void(0)" className='product-media-shap'>
                                    <figure className='product-media'>
                                        <Skeleton variant="rectangular" width={700} height={800} />
                                    </figure>
                                    <div className='product-action-vertical'>
                                        <a href="javascript:void(0)" className="btn-product-icon btn-wishlist" title="Add to wishlist">
                                        <Skeleton variant="rectangular" width={20} height={20} />
                                        </a>
                                    </div>
                                    <div className='product-label'>
                                        <span className='label-new'><Skeleton variant="rectangular" width={30} height={10} /></span>
                                    </div>
                                    <button className='addcartbutton'><i className="ri-add-line"></i></button>
                                </a>
                                <div className='product-details'>
                                    <h2 className="product-name"> <a href="javascript:void(0)"><Skeleton variant="rectangular" width={200} height={20} /></a></h2>
                                    <div className="product-price">
                                        <ins className="new-price"><Skeleton variant="rectangular" width={60} height={20} /></ins>
                                        <del className="old-price"><Skeleton variant="rectangular" width={60} height={20} /></del>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            :
            resProductData && resProductData.length > 0 ?
                <section className="section-gap-sm">
                    <div className="container-fluid">
                        <div className="row">
                            {resProductData.map((subvalue, indexProduct) => {
                                return (
                                    <div className="col-lg-3">
                                        <ProductBox productValue={subvalue} classType="product" key={indexProduct}></ProductBox>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {totalPages > 1 && (<PaginationControls currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />)}
                </section>
                : <div className='noimg'>
                    <img src='/img/noproduct.png'></img>
                    <h4 className='mt-40'>No Products Found</h4>
                </div>
            }
        <Footer></Footer>
    </>)
}

export default ProductList